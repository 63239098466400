import * as React from "react";
import { Box, Divider } from "@mui/material";

// project import
import Header from "../components/Header/Header";
import SidebarLabels from "../components/SideBarLabels/SideBarLabels";
import ContactsAppBar from "../components/Contacts/ContactsAppBar";
import Contacts from "../components/Contacts/Contacts";
import ShareLabelsDialog from "../components/ShareLabelDialog/ShareLabelsDialog";
import AddNewContactDialog from "../components/Contacts/AddNewContactDialog";
import AddExistingContactsDialog from "../components/Contacts/AddExistingContactsDialog";
import CreateLabelDialog from "../components/SideBarLabels/CreateLabelDialog";
import RenameLabelDialog from "../components/SideBarLabels/RenameLabelDialog";
import AlertDeleteLabelDialog from "../components/SideBarLabels/AlertDeleteLabelDialog";
import AlertRemoveContactFromLabelDialog from "../components/Contacts/AlertRemoveContactFromLabelDialog";
import WarningCantDeleteContactFromLabel from "../components/Contacts/WarningCantDeleteContactFromLabel";
import EditContactDialog from "../components/Contacts/EditContactDialog";
import AlertDeleteContactDialog from "../components/Contacts/AlertDeleteContactDialog";
import AskPhoneDialog from "../components/AskPhoneDialog/AskPhoneDialog";
import WarningShareAllContactsDialog from "../components/SideBarLabels/WarningShareAllContactsDialog";
import MeetingDialog from "../components/Contacts/MeetingDialog";
import WelcomeDialog from "../components/WelcomeDialog/WelcomeDialog";
import InvitationPendingDialog from "../components/SideBarLabels/InvitationPendingDialog";
import RevokedCollaboratorDialog from "../components/SideBarLabels/RevokedCollaboratorDialog";
import Layout from "../layouts/Layout";

export default function ContactsPage() {
    return (
        <>
            <Layout />
            <ShareLabelsDialog />
            <AddNewContactDialog />
            <EditContactDialog />
            <AddExistingContactsDialog />
            <CreateLabelDialog />
            <RenameLabelDialog />
            <AlertDeleteLabelDialog />
            <AlertRemoveContactFromLabelDialog />
            <WarningCantDeleteContactFromLabel />
            <AlertDeleteContactDialog />
            {/* <AskPhoneDialog/> */}
            <WarningShareAllContactsDialog />
            <MeetingDialog />
            <WelcomeDialog />
            <InvitationPendingDialog />
            <RevokedCollaboratorDialog />
        </>
    )
}
