import { Box, Stack } from "@mui/material";
import { CheckOutlined, ModeEditOutlineOutlined, RemoveRedEyeOutlined, ShareOutlined } from "@mui/icons-material";

export default function ModifiedMenuItem({ showCheck, color, name, showIcon }) {
    let icon = '';
    if (name === 'SHARE') {
        icon = <ShareOutlined sx={{
            width: 20,
            height: 20,
            color
        }} />;
    } else if (name === 'EDIT') {
        icon = <ModeEditOutlineOutlined sx={{
            width: 20,
            height: 20,
            color
        }} />;
    } else if (name === 'VIEW') {
        icon = <RemoveRedEyeOutlined sx={{
            width: 20,
            height: 20,
            color
        }} />;
    }
    const text = name === 'SHARE' ? 'Reshare' : name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    return <Stack
        direction="row"
        alignItems="center"
        sx={{
            width: '100%',
            position: 'relative'
        }}
    >
        <Stack direction="row" alignItems="center" spacing={'6px'}>
            {showIcon ? icon : null}
            <Box sx={{ color }}>
                {text}
            </Box>
        </Stack>
        {showCheck && (
            <CheckOutlined sx={{
                width: 20,
                height: 20,
                position: 'absolute',
                right: 0,
                color: '#215924'
            }} />
        )}
    </Stack>;
}