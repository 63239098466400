import * as React from 'react';
import {
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridHeaderCheckbox
} from "@mui/x-data-grid";
import { Box, Chip, Stack, Tooltip, Typography, IconButton, SvgIcon, Checkbox } from "@mui/material";
import { useSelector } from "react-redux";
import {
    LabelOffOutlined,
    ModeEditOutlined,
    DeleteOutlineOutlined,
} from '@mui/icons-material';
import { useEffect, useMemo, useState, useRef } from "react";
import { throttle } from "lodash";
import { styled } from "@mui/material/styles";

// project import
import store from "../../store/store";
import { selectGroups, changeContacts } from "../../store/reducers/groupsSlice";
import {
    openAddContactToLabelsDialog, openAlertDeleteContactDialog,
    openAlertRemoveContactFromLabelDialog,
    openEditContactDialog,
} from "../../store/reducers/dialogSlice";
import { getFullContact, setRowSelectionModel, setContactsToDelete } from "../../store/reducers/contactsSlice";
import AddContactsToLabelsDialog from "./AddContactsToLabelsDialog";
import { StyledDataGrid } from "../StyledDataGrid";
import { checkRole, isDisabledDeleteLabel } from "../../services/checkRole";
import * as RolesConstants from "../../constants/roles";
import AddContactsMenu from "./AddContactsMenu";
import { DATA_GRID_HEADER_HEIGHT, ROW_HEIGHT } from "../../constants/size";
import CustomLoadingOverlay from "../CustomLoadingOverlay";
import { UserAvatar } from "../UserAvatar";
import Labels from "./Labels";

// Modify the StyledDataGrid component, adding rules for selected elements
const StyledDataGridWithHiddenCheckboxes = styled(StyledDataGrid)(({ theme }) => ({
    '& .MuiDataGrid-columnHeaderDraggableContainer[data-field="__check__"]': {
        width: '0px !important',
        padding: '0 !important',
        border: 'none !important',
    },
    '& .avatar-checkbox-container': {
        position: 'relative',
        width: 40,
        height: 40,
    },
    '& .avatar-container': {
        transition: 'opacity 0.2s',
    },
    '& .checkbox-container': {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0,
        transition: 'opacity 0.2s',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
    },
    '& .MuiDataGrid-row:hover .avatar-container': {
        opacity: 0,
    },
    '& .MuiDataGrid-row:hover .checkbox-container': {
        opacity: 1,
    },
    '& .selected-row .avatar-container': {
        opacity: 0,
        transition: 'opacity 0s',
    },
    '& .selected-row .checkbox-container': {
        opacity: 1,
        transition: 'opacity 0s',
        backgroundColor: '#D1E1FA',
        borderRadius: '50%',
    },
    '& .selected-row .MuiCheckbox-root': {
        backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-row:hover .MuiDataGrid-cell.selected-row': {
        backgroundColor: '#C6D9F7 !important',
    },
    '& .selected-row .MuiCheckbox-root .MuiSvgIcon-root': {
        color: '#1976d2',
    },
    '& .MuiDataGrid-row.checkbox-hovered': {
        backgroundColor: 'transparent !important',
    },
    '& .checkbox-hovered .checkbox-container': {
        opacity: 1,
        backgroundColor: '#F5F5F5',
        borderRadius: '50%',
    },
    '& .checkbox-hovered .avatar-container': {
        opacity: 0,
    },
    '& .selected-checkbox': {
        backgroundColor: '#D1E1FA !important',
        borderRadius: '50%',
    },
    '& .MuiDataGrid-row:hover .selected-checkbox': {
        backgroundColor: '#C6D9F7 !important',
        borderRadius: '50%',
    },
    '& .selected-row .checkbox-container:hover': {
        backgroundColor: '#BECFE8 !important',
    },
}));

// Create a component for displaying avatar or checkbox
const AvatarWithCheckbox = ({ params, selected, onCheckboxChange }) => {
    // Handler for mouse entering the checkbox
    const handleMouseEnter = (e) => {
        e.stopPropagation();
        // Find the closest row and add the class
        const row = e.currentTarget.closest('.MuiDataGrid-row');
        if (row) {
            row.classList.add('checkbox-hovered');
        }
    };

    // Handler for mouse leaving the checkbox
    const handleMouseLeave = (e) => {
        e.stopPropagation();
        // Find the closest row and remove the class
        const row = e.currentTarget.closest('.MuiDataGrid-row');
        if (row) {
            row.classList.remove('checkbox-hovered');
        }
    };

    // Handler for keyboard
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.stopPropagation();
            onCheckboxChange(e, params.row.resourceName);
        }
    };

    return (
        <div
            className="avatar-checkbox-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={(e) => {
                e.stopPropagation();
                onCheckboxChange(e, params.row.resourceName);
            }}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
            aria-label={selected ? "Deselect" : "Select row"}
        >
            <div className="avatar-container">
                <UserAvatar
                    alt={params.row.name?.displayName}
                    src={params.row.photos?.[0]?.url}
                    sx={{ width: 40, height: 40 }}
                />
            </div>
            <div
                className={`checkbox-container ${selected ? 'selected-checkbox' : ''}`}
            >
                <Checkbox
                    checked={selected}
                    onChange={(e) => onCheckboxChange(e, params.row.resourceName)}
                    onClick={(e) => e.stopPropagation()}
                    sx={{
                        padding: 0,
                        width: 40,
                        height: 40,
                        '& .MuiSvgIcon-root': {
                            width: 24,
                            height: 24,
                        },
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                        ...(selected && {
                            backgroundColor: 'transparent',
                            '& + .MuiTouchRipple-root': {
                                backgroundColor: '#D1E1FA',
                                borderRadius: '50%',
                            }
                        })
                    }}
                />
            </div>
        </div>
    );
};

// Component for header with select all contacts
const NameColumnHeader = ({ params, rowSelectionModel, contactsLength, onSelectAllClick }) => {
    const [isHeaderHovered, setIsHeaderHovered] = useState(false);

    const isIndeterminate = rowSelectionModel.length > 0 && rowSelectionModel.length < contactsLength;
    const isChecked = rowSelectionModel.length > 0 && rowSelectionModel.length === contactsLength;

    return (
        <Stack
            direction="row"
            spacing={'20px'}
            alignItems="center"
            onMouseEnter={() => setIsHeaderHovered(true)}
            onMouseLeave={() => setIsHeaderHovered(false)}
            sx={{ width: '100%', height: '100%', cursor: 'pointer' }}
        >
            <Box sx={{ width: 40, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {(rowSelectionModel.length > 0 || isHeaderHovered) ? (
                    <Checkbox
                        indeterminate={isIndeterminate}
                        checked={isChecked}
                        onChange={onSelectAllClick}
                        onClick={(e) => e.stopPropagation()}
                        sx={{ padding: 0 }}
                    />
                ) : null}
            </Box>

            {rowSelectionModel.length > 0 ? (
                <Typography sx={{
                    color: '#3B7DED',
                    fontFamily: 'Open Sans',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '24px',
                    letterSpacing: '0',
                }}>Selected: {rowSelectionModel.length}</Typography>
            ) : (
                <span>{params.colDef.headerName}</span>
            )}
        </Stack>
    );
};

export default function Contacts({ topOffset }) {
    const statusFirstLoad = useSelector((state) => state.contacts.statusFirstLoad);
    const contacts = useSelector((state) => state.contacts.contacts);
    const selectedContacts = useSelector((state) => state.contacts.selectedContacts);
    const rowSelectionModel = useSelector((state) => state.contacts.rowSelectionModel);
    const groups = useSelector(selectGroups);
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const userInitialized = useSelector((state) => state.user.userInitialized);
    const domainInfoInitialized = useSelector((state) => state.user.domainInfoInitialized);
    const isCheckedContacts = !!rowSelectionModel.length;
    const [sortModel, setSortModel] = React.useState([]);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useEffect(() => {
        const handleResize = throttle(() => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }, 200);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [topOffset]);
    const slots = useMemo(() => ({
        noRowsOverlay: NoRowsOverlay,
        loadingOverlay: CustomLoadingOverlay,
    }), []);

    if (!userInitialized || !domainInfoInitialized) return null; // Waiting for Alerts information to prevent table drift
    const handleClickCopyContactsToLabel = async () => {
        store.dispatch(openAddContactToLabelsDialog(true));
    };
    const handleClickRemoveContactsFromGroup = async () => {
        store.dispatch(openAlertRemoveContactFromLabelDialog(true));
    };
    const handleClickEditContact = async (event, id) => {
        event.stopPropagation();
        store.dispatch(getFullContact(id));
        store.dispatch(openEditContactDialog(true));
    };
    const handleClickDeleteOneContact = async (event, id) => {
        event.stopPropagation();
        await store.dispatch(setContactsToDelete([id]));
        store.dispatch(openAlertDeleteContactDialog(true));
    };
    const handleClickDeleteContacts = async () => {
        await store.dispatch(setContactsToDelete(rowSelectionModel));
        store.dispatch(openAlertDeleteContactDialog(true));
    };

    // Handler for checkbox in cell
    const handleCheckboxChange = (event, id) => {
        event.stopPropagation();
        const newSelection = rowSelectionModel.includes(id)
            ? rowSelectionModel.filter(contactId => contactId !== id)
            : [...rowSelectionModel, id];
        store.dispatch(setRowSelectionModel(newSelection));
    };

    // Handler for selecting all contacts
    const handleSelectAllClick = (event) => {
        if (rowSelectionModel.length > 0) {
            store.dispatch(setRowSelectionModel([]));
        } else {
            const contactsToSelect = currentGroup.resource === 'contactGroups/myContacts'
                ? contacts.map(contact => contact.resourceName)
                : currentGroup.contacts_resources;
            store.dispatch(setRowSelectionModel(contactsToSelect));
        }
    };

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 140,
            flex: 1,
            sortable: !isCheckedContacts,
            disableColumnMenu: isCheckedContacts,
            renderHeader: (params) => (
                <NameColumnHeader
                    params={params}
                    rowSelectionModel={rowSelectionModel}
                    contactsLength={contacts.length}
                    onSelectAllClick={handleSelectAllClick}
                />
            ),
            valueGetter: (params) => {
                return params.value?.displayName?.trim() || params.row.emailAddresses?.[0]?.value;
            },
            renderCell: params => (
                <Stack direction={'row'} spacing={'20px'} sx={{ alignItems: 'center' }}>
                    <AvatarWithCheckbox
                        params={params}
                        selected={rowSelectionModel.includes(params.row.resourceName)}
                        onCheckboxChange={handleCheckboxChange}
                    />
                    <Typography
                        sx={{
                            color: "rgba(0, 0, 0, 0.87)",
                            fontSize: 14,
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "20px",
                            letterSpacing: "0.2px",
                        }}
                    >
                        {params.value}
                    </Typography>
                </Stack>
            ),
        },
        {
            field: 'emailAddresses',
            headerName: 'Email',
            minWidth: 128,
            flex: 1,
            sortable: !isCheckedContacts,
            disableColumnMenu: isCheckedContacts,
            renderHeader: (params) => (
                isCheckedContacts ? <></> : <span>
                    {params.colDef.headerName}
                </span>
            ),
            valueGetter: (params) => {
                return params.value?.[0]?.value;
            },
        },
        {
            field: 'phoneNumbers',
            headerName: 'Phone number',
            minWidth: 128,
            flex: 1,
            sortable: !isCheckedContacts,
            disableColumnMenu: isCheckedContacts,
            renderHeader: (params) => (
                isCheckedContacts ? <></> : <span>
                    {params.colDef.headerName}
                </span>
            ),
            valueGetter: (params) => {
                return params.value?.[0]?.value;
            },
        },
        {
            field: 'memberships',
            headerName: 'Labels',
            minWidth: 353.3,
            sortable: !isCheckedContacts,
            disableColumnMenu: isCheckedContacts,
            renderHeader: (params) => (
                isCheckedContacts ? <></> : <span>
                    {params.colDef.headerName}
                </span>
            ),
            renderCell: (params) => {
                const filteredMemberships = params.row.memberships.filter(item => item !== 'contactGroups/myContacts');
                const filteredGroups = groups.filter(group => filteredMemberships.includes(group.resource));
                return <Labels filteredGroups={filteredGroups} />
            }
        },
        {
            field: 'action',
            headerName: '',
            width: 168,
            sortable: false,
            disableColumnMenu: true,
            align: 'right',
            headerAlign: 'right',
            renderHeader: (params) => (
                isCheckedContacts ? <>
                    <Tooltip title='Copy contacts to labels'>
                        <IconButton
                            size="medium"
                            onClick={handleClickCopyContactsToLabel}
                        >
                            <SvgIcon>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17.63 5.84C17.27 5.33 16.67 5 16 5L5 5.01C3.9 5.01 3 5.9 3 7V17C3 18.1 3.9 18.99 5 18.99L16 19C16.67 19 17.27 18.67 17.63 18.16L22 12L17.63 5.84ZM16 17H5V7H16L19.55 12L16 17Z"
                                        fill="#3B7DED" />
                                    <path
                                        d="M12 10.13C8.11 10.67 6.56 13.33 6 16C7.39 14.13 9.22 13.28 12 13.28V15.47L16 11.73L12 8V10.13Z"
                                        fill="#3B7DED" />
                                </svg>
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Remove contacts from label'>
                        <span>
                            <IconButton
                                disabled={isDisabledDeleteLabel(currentGroup)}
                                size="medium"
                                onClick={handleClickRemoveContactsFromGroup}
                            >
                                <LabelOffOutlined
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        flexShrink: 0,
                                    }}
                                />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        title='Delete contacts'
                    >
                        <span>
                            <IconButton
                                onClick={handleClickDeleteContacts}
                                disabled={!currentGroup?.is_user_owner}
                            >
                                <DeleteOutlineOutlined
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        flexShrink: 0,
                                    }}
                                />
                            </IconButton>
                        </span>
                    </Tooltip>
                </> : <span>
                    {params.colDef.headerName}
                </span>

            ),
            renderCell: params => {
                return (
                    <Box className="actionIcons" sx={{ ml: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                        {checkRole(RolesConstants.ROLE_EDIT, currentGroup) && (
                            <Tooltip
                                placement="bottom"
                                title='Edit contact'
                            >
                                <IconButton
                                    onClick={(event) => handleClickEditContact(event, params.row.resourceName)}
                                    sx={{
                                        color: 'primary.main',
                                        '&:hover': {
                                            backgroundColor: 'rgba(25, 118, 210, 0.04)',
                                        },
                                    }}
                                >
                                    <ModeEditOutlined
                                        sx={{
                                            width: 24,
                                            height: 24,
                                            flexShrink: 0,
                                            color: 'rgba(0, 0, 0, 0.54)',
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                        {currentGroup?.is_user_owner && (
                            <Tooltip
                                placement="bottom"
                                title='Delete contact'
                            >
                                <IconButton onClick={(event) => handleClickDeleteOneContact(event, params.row.resourceName)}>
                                    <DeleteOutlineOutlined
                                        sx={{
                                            width: 24,
                                            height: 24,
                                            flexShrink: 0,
                                            color: 'rgba(0, 0, 0, 0.54)',
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                )
            }
        },
    ];

    const rows = selectedContacts;
    return (
        <>
            <StyledDataGridWithHiddenCheckboxes
                key={`${windowSize.width}-${windowSize.height}`}
                autoHeight={false}
                sx={{
                    height: '100%',
                    width: 'calc(100% - 20px)',
                    '& .MuiDataGrid-cell': {
                        display: 'flex',
                        alignItems: 'center',
                        '& .MuiChip-root': {
                            border: '1px solid rgba(0, 0, 0, 0.12) !important',
                            borderRadius: '8px',
                        },
                    },
                }}
                disableSelectionOnClick
                rows={rows}
                columns={columns}
                getRowId={(row) => row.resourceName}
                columnHeaderHeight={DATA_GRID_HEADER_HEIGHT}
                rowHeight={ROW_HEIGHT}
                autoPageSize
                checkboxSelection={false}
                disableRowSelectionOnClick
                disableColumnSelector
                loading={statusFirstLoad === 'loading'}
                getCellClassName={(params) => {
                    return rowSelectionModel.includes(params.row.resourceName) ? 'selected-row' : '';
                }}
                onRowClick={(params) => {
                    const newSelection = rowSelectionModel.includes(params.row.resourceName)
                        ? rowSelectionModel.filter(contactId => contactId !== params.row.resourceName)
                        : [...rowSelectionModel, params.row.resourceName];
                    store.dispatch(setRowSelectionModel(newSelection));
                }}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                slots={slots}
                slotProps={{
                    loadingOverlay: { topOffset: topOffset + 230, rowHeight: 72 },
                }}
            />
            <AddContactsToLabelsDialog />
        </>
    );
}

const NoRowsOverlay = () => {
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    return (
        <Stack height="100%" alignItems="center" justifyContent="center" spacing={3}>
            <SvgIcon sx={{ width: 48, height: 48 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="none">
                    <path
                        d="M20.5 24C24.92 24 28.5 20.42 28.5 16C28.5 11.58 24.92 8 20.5 8C16.08 8 12.5 11.58 12.5 16C12.5 20.42 16.08 24 20.5 24ZM20.5 12C22.7 12 24.5 13.8 24.5 16C24.5 18.2 22.7 20 20.5 20C18.3 20 16.5 18.2 16.5 16C16.5 13.8 18.3 12 20.5 12Z"
                        fill="black" fillOpacity="0.54" />
                    <path
                        d="M8.5 36C8.94 34.56 15.12 32 20.5 32C20.5 30.6 20.76 29.26 21.2 28.02C15.74 27.82 4.5 30.54 4.5 36V40H23.58C22.54 38.84 21.72 37.5 21.2 36H8.5Z"
                        fill="black" fillOpacity="0.54" />
                    <path
                        d="M29.32 40L34.5 34.82L39.68 40L42.5 37.18L37.32 32L42.5 26.82L39.68 24L34.5 29.18L29.32 24L26.5 26.82L31.68 32L26.5 37.18L29.32 40Z"
                        fill="black" fillOpacity="0.54" />
                </svg>
            </SvgIcon>
            <Box
                sx={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '8px',
                    width: 300,
                }}
            >
                <Typography
                    sx={{
                        color: 'rgba(60, 64, 67, 1)',
                        fontFamily: 'Open Sans',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '24px',
                        letterSpacing: '-0.72px',
                    }}
                >
                    There are no contacts in this label yet
                </Typography>
                <Typography
                    sx={{
                        color: 'rgba(0, 0, 0, 0.60)',
                        fontFamily: 'Open Sans',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        letterSpacing: '-0.56px',
                        textAlign: 'center',
                    }}
                >
                    Add contacts and they will be displayed there
                </Typography>
            </Box>
            {checkRole(RolesConstants.ROLE_EDIT, currentGroup) && <AddContactsMenu buttonType={'button'} />}
        </Stack>
    );
}
