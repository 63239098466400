import {
    take,
    takeEvery,
    takeLatest,
    takeLeading,
    delay,
    put,
    call,
    fork,
    spawn,
    join,
    select,
    actionChannel,
    cancel,
    all,
} from 'redux-saga/effects';

import { GET_DATA, getDataSaga, getUserSaga, getUserSuccess, getSubscriptionSaga, getSubscriptionSuccess } from '../reducers/userSlice';
import { loadBasicData, loadContactsAndLabels } from "./initialSagas";
import { showNotification } from "../reducers/notificationSlice";
// import { loadPartContactsAndLabels } from "./Contacts/getPartContactsAndLabels";
// const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));



export function* workerSaga() {
    // const loadContactsAndLabelsTask = yield fork(loadContactsAndLabels);
    const loadBasicDataTask = yield fork(loadBasicData);
    // const user = yield join(task);
    // console.log('User data', user);
    // const userState = yield select(state => state.user);
    // console.log('userState', userState);
}

export function* workerGetPartContactsAndLabelsSaga() {
    // const loadContactsAndLabelsTask = yield fork(loadPartContactsAndLabels);
}

export function* watchLoadDataSaga() {
    yield takeEvery('LOAD_DATA', workerSaga);
}

export function* watchGetPartContactsAndLabelsSaga() {
    yield takeEvery('GET_PART_CONTACTS_AND_LABELS', workerGetPartContactsAndLabelsSaga);
}


export default function* rootSaga() {
    const sagas = [
        watchLoadDataSaga,
        // loadContactsAndLabels,
        loadBasicData,
        // watchGetPartContactsAndLabelsSaga,
        // loadPartContactsAndLabels,
    ];

    // const retrySagas = yield sagas.map(saga => {
    //     return spawn(function* () {
    //         while (true) {
    //             try {
    //                 yield call(saga);
    //                 break;
    //             } catch (e) {
    //                 console.log(e);
    //             }
    //         }
    //     })
    // });
    // yield all(retrySagas);

    // yield all(
    //     sagas.map(saga => {
    //         return spawn(function* () {
    //             try {
    //                 yield call(saga);
    //             } catch (e) {
    //                 console.log(e);
    //                 const errorMessage = 'An error occurred';
    //                 yield put(showNotification({
    //                     type: 'error',
    //                     message: errorMessage,
    //                 }))
    //             }
    //         })
    //     })
    // )

    const retrySagas = yield sagas.map(saga => {
        return spawn(function* () {
            let attempts = 0;
            while (attempts < 1) {
                try {
                    yield call(saga);
                    break;
                } catch (e) {
                    console.log(`Attempt ${attempts + 1}:`, e);
                    attempts += 1;
                    const errorMessage = 'An error occurred';
                    yield put(showNotification({
                        type: 'error',
                        message: errorMessage,
                    }))
                }
            }
        });
    });
    yield all(retrySagas);


}
