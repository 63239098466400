import { FormControl, MenuItem, Select } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

import store from "../../store/store";
import { changeUserRole } from "../../store/reducers/groupsSlice";
import ModifiedMenuItem from "./ModifiedMenuItem";

export default function PermissionsSelectForOneUser({ user, isViewPermission }) {
    const groupsToShare = useSelector((state) => state.groups.groupsToShare);
    const accountUser = useSelector((state) => state.user.user);
    const [permission, setPermission] = useState(user?.role || '');
    let userShareInfo;
    if (accountUser.user_id === user.user_id) {
        userShareInfo = { ...accountUser, role: user.role };
    } else {
        userShareInfo = groupsToShare[0].share.find(item => item.email === user.email) || groupsToShare[0].domains_groups_share.find(item => item.email === user.email);
    }
    const [menuColors, setMenuColors] = useState({
        VIEW: false,
        EDIT: false,
        SHARE: false,
        OWNER: false
    });
    useEffect(() => {
        if (user && user.role && user.role !== permission) {
            setPermission(user.role);
        }
    }, [user, user.role]);

    const shouldShowCheck = (currentValue) => {
        switch (permission) {
            case 'VIEW':
                return currentValue === 'VIEW';
            case 'EDIT':
                return ['VIEW', 'EDIT'].includes(currentValue);
            case 'SHARE':
                return ['VIEW', 'EDIT', 'SHARE'].includes(currentValue);
            case 'OWNER':
                return ['VIEW', 'EDIT', 'SHARE', 'OWNER'].includes(currentValue);
            default:
                return false;
        }
    };
    const handleChange = async (event) => {
        setPermission(event.target.value);
        const data = {
            group: groupsToShare[0].resource,
            userId: userShareInfo.user_id,
            email: userShareInfo.email,
            role: event.target.value,
        }
        store.dispatch(changeUserRole(data));
    };
    if (user.role === 'owner') {
        return <Typography
            sx={{
                color: 'rgba(0, 0, 0, 1)',
                fontSize: 14,
                fontFamily: "Roboto",
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0.2px',
            }}
        >
            Owner
        </Typography>
    }
    const getRoleText = (user) => {
        switch (user.role) {
            case 'VIEW':
                return 'View';
            case 'EDIT':
                return 'Edit';
            case 'SHARE':
                return 'Reshare';
            default:
                return '';
        }
    };
    if (isViewPermission || accountUser.email === user.email) {
        const roleText = getRoleText(user);
        return <Typography
            sx={{
                color: 'rgba(0, 0, 0, 1)',
                fontSize: 14,
                fontFamily: "Roboto",
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0.2px',
            }}
        >
            {roleText}
        </Typography>
    }

    const getBackgroundColor = (currentValue) => {
        return shouldShowCheck(currentValue) ? '#EBF5EB' : 'white';
    };

    const getColor = (currentValue) => {
        return shouldShowCheck(currentValue) ? '#215924' : 'rgba(0, 0, 0, 0.84)';
    };

    return (
        <FormControl variant="standard" fullWidth>
            <Select
                disableUnderline
                sx={{
                    color: 'rgba(0, 0, 0, 1)',
                    fontSize: 14,
                    fontFamily: "Roboto",
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    letterSpacing: '0.2px',
                    backgroundColor: 'inherit',
                    '& .MuiSelect-select': {
                        backgroundColor: 'inherit',
                    },
                    '&.Mui-focused .MuiSelect-select': {
                        backgroundColor: 'inherit',
                    },
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            '& .MuiMenuItem-root': {
                                fontSize: '16px',
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '20px',
                                letterSpacing: '0.16px',
                                marginBottom: '8px',
                                width: '144px',
                                height: '38px',
                                borderRadius: '4px',
                                marginLeft: '8px',
                                display: 'flex',
                                alignItems: 'center',
                                '&:hover': {
                                    backgroundColor: '#D7EAD8',
                                },
                                '&:last-child': {
                                    marginBottom: 0
                                }
                            },
                            '& .MuiMenuItem-root.Mui-selected': {
                                backgroundColor: '#EBF5EB !important',
                                '&:hover': {
                                    backgroundColor: '#D7EAD8 !important'
                                }
                            }
                        },
                    },
                }}
                labelId="demo-simple-select-label"
                id="permission-select"
                value={permission || user.role}
                label="Permission"
                onChange={handleChange}
                renderValue={(selected) => {
                    return <ModifiedMenuItem showCheck={false} color='rgba(0, 0, 0, 0.84)' name={selected} showIcon={false} />
                }}
            >
                <MenuItem
                    value={'VIEW'}
                    sx={{ backgroundColor: getBackgroundColor('VIEW'), border: shouldShowCheck('VIEW') ? null : '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '4px' }}
                    onClick={() => setMenuColors(prev => {
                        return {
                            VIEW: true,
                            EDIT: false,
                            SHARE: false,
                            OWNER: false
                        }
                    })}
                >

                    <ModifiedMenuItem showCheck={shouldShowCheck('VIEW')} color={getColor('VIEW')} name={'VIEW'} showIcon />
                </MenuItem>
                <MenuItem
                    value={'EDIT'}
                    sx={{ backgroundColor: getBackgroundColor('EDIT'), border: shouldShowCheck('EDIT') ? null : '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '4px' }}
                    onClick={() => setMenuColors(prev => {
                        return {
                            VIEW: true,
                            EDIT: true,
                            SHARE: false,
                            OWNER: false
                        }
                    })}
                >
                    <ModifiedMenuItem showCheck={shouldShowCheck('EDIT')} color={getColor('EDIT')} name={'EDIT'} showIcon />
                </MenuItem>
                <MenuItem
                    value={'SHARE'}
                    sx={{ backgroundColor: getBackgroundColor('SHARE'), border: shouldShowCheck('SHARE') ? null : '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '4px' }}
                    onClick={() => setMenuColors(prev => {
                        return {
                            VIEW: true,
                            EDIT: true,
                            SHARE: true,
                            OWNER: false
                        }
                    })}
                >
                    <ModifiedMenuItem showCheck={shouldShowCheck('SHARE')} color={getColor('SHARE')} name={'SHARE'} showIcon />
                </MenuItem>
                {/* <MenuItem
                    value={'OWNER'}
                    // disabled
                    sx={{ backgroundColor: getBackgroundColor('OWNER') }}
                    onClick={() => setMenuColors(prev => {
                        return {
                            VIEW: true,
                            EDIT: true,
                            SHARE: true,
                            OWNER: true
                        }
                    })}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                            width: '100%',
                            position: 'relative'
                        }}
                    >
                        <Stack direction="row" alignItems="center" spacing={'6px'}>
                            <ManageAccountsOutlined sx={{
                                width: 20,
                                height: 20,
                                color: getColor('OWNER')
                            }} />
                            <Box sx={{ color: getColor('OWNER') }}>Owner</Box>
                        </Stack>
                        {shouldShowCheck('OWNER') && (
                            <CheckOutlined sx={{
                                width: 20,
                                height: 20,
                                position: 'absolute',
                                right: 0,
                                color: '#215924'
                            }} />
                        )}
                    </Stack>
                </MenuItem> */}
            </Select>
        </FormControl>
    );
}
