import * as RolesConstants from '../constants/roles'

const isDisabledDeleteLabel = (group) => {
    if (group.resource === 'contactGroups/myContacts') return true;
    return !(group?.role === 'SHARE' || group?.role === 'EDIT');
};

const checkRole = (role, group) => {
    if (!group || Object.keys(group).length === 0) {
        return false;
    }
    if (group.is_user_owner) {
        return true;
    }
    if (role === RolesConstants.ROLE_EDIT) {
        return [RolesConstants.ROLE_EDIT, RolesConstants.ROLE_SHARE].includes(group.role);
    }
    if (role === RolesConstants.ROLE_SHARE) {
        return group.role === RolesConstants.ROLE_SHARE;
    }
    return false;
};

export {
    checkRole,
    isDisabledDeleteLabel,
}
