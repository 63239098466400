const getSharedUsers = (group, user, contacts, shareableUsers, subscription) => {
    if (!group) return [];
    let shared = [];
    const groupRole = group.role;
    const me = { ...user, id: user.email, role: groupRole, shareType: 'USER', sync_status: 'OK' };
    if (user.user_id === group.owner || group.owner === 0) {
        me.role = 'owner';
    }
    shared.push(me);
    shared = [...shared, ...group.domains_groups_share.map(domainGroupShare => ({
        ...domainGroupShare,
        shareType: 'GROUP',
        id: domainGroupShare.email,
    }))];
    /* eslint-disable-next-line */
    for (const sharedGroup of group.share) {
        const oneShared = { ...sharedGroup };
        if (oneShared.type === 2) {
            /* eslint-disable-next-line */
            continue
        }
        const email = oneShared.email;
        const contact = contacts.find(
            element => Array.isArray(element.emailAddresses) && element.emailAddresses.some(emailObj => emailObj.value === email)
        );

        if (contact) {
            oneShared.name = contact.name?.displayName;
            oneShared.photo = contact.photos?.[0]?.url;
        } else {
            const shareableUser = shareableUsers.users.find(
                element => element.email === email
            );
            if (shareableUser) {
                oneShared.name = shareableUser.name;
                oneShared.photo = shareableUser.photo;
            }
        }
        if (oneShared.user_id === group.owner) {
            oneShared.role = 'owner'
        }
        oneShared.shareType = 'USER'
        oneShared.id = oneShared.email;
        shared.push(oneShared)
    }
    return shared;
};

export { getSharedUsers }
