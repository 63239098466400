import { Avatar, SvgIcon } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";

export function UserAvatar({ src, sx, alt, isProfileAvatar }) {
    if (!sx) {
        sx = { width: 40, height: 40 }
    }
    function capitalizeFirstLetter(string) {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const capitalizedAlt = capitalizeFirstLetter(alt);
    const DefaultAvatarImage40x40 = <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="20" fill="#E4E6E7" />
        <path
            d="M19.998 21.1111C22.9905 21.1111 28.998 22.6 28.998 25.5556V25.9111C26.8155 28.4 23.598 30 19.998 30C16.398 30 13.1805 28.4 10.998 25.9111V25.5556C10.998 22.6 17.0055 21.1111 19.998 21.1111ZM19.998 18.8889C17.523 18.8889 15.498 16.8889 15.498 14.4444C15.498 12 17.523 10 19.998 10C22.473 10 24.498 12 24.498 14.4444C24.498 16.8889 22.473 18.8889 19.998 18.8889Z"
            fill="#8A8D93" />
    </svg>
    let avatar;
    const AvatarComponent = isProfileAvatar ? StyledAvatar : Avatar;
    if (src) {
        avatar = <AvatarComponent sx={sx} src={src} alt={capitalizedAlt} />;
    } else {
        avatar = <AvatarComponent alt={capitalizedAlt} sx={sx}>
            <SvgIcon sx={sx}>
                {DefaultAvatarImage40x40}
            </SvgIcon>
        </AvatarComponent>

    }
    return avatar
}

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    cursor: 'pointer',
    '&:hover': {
        boxShadow: '0 0 0 4px #F5F5F5',
    },
}));
