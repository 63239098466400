import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from 'react';
import ModifiedMenuItem from './ModifiedMenuItem';

export default function PermissionsSelect({ permission, setPermission }) {
    const [menuColors, setMenuColors] = useState({
        VIEW: false,
        EDIT: false,
        SHARE: false,
        OWNER: false
    });

    const shouldShowCheck = (currentValue) => {
        switch (permission) {
            case 'VIEW':
                return currentValue === 'VIEW';
            case 'EDIT':
                return ['VIEW', 'EDIT'].includes(currentValue);
            case 'SHARE':
                return ['VIEW', 'EDIT', 'SHARE'].includes(currentValue);
            case 'OWNER':
                return ['VIEW', 'EDIT', 'SHARE', 'OWNER'].includes(currentValue);
            default:
                return false;
        }
    };

    const handleChange = (event) => {
        setPermission(event.target.value);
    };

    const getBackgroundColor = (currentValue) => {
        return shouldShowCheck(currentValue) ? '#EBF5EB' : 'white';
    };

    const getColor = (currentValue) => {
        return shouldShowCheck(currentValue) ? '#215924' : 'rgba(0, 0, 0, 0.84)';
    };

    return (
        <FormControl
            variant="outlined"
            size='small'
            sx={{ width: 160 }}
        >
            <InputLabel
                id="demo-simple-select-label"
                sx={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0.15px',
                }}
            >
                Permissions
            </InputLabel>
            <Select
                sx={{
                    color: '#292929',
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0.15px',
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            '& .MuiMenuItem-root': {
                                fontSize: '16px',
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '20px',
                                letterSpacing: '0.16px',
                                marginBottom: '8px',
                                width: '144px',
                                height: '38px',
                                borderRadius: '4px',
                                marginLeft: '8px',
                                display: 'flex',
                                alignItems: 'center',
                                '&:hover': {
                                    backgroundColor: '#D7EAD8',
                                },
                                '&:last-child': {
                                    marginBottom: 0
                                }
                            },
                            '& .MuiMenuItem-root.Mui-selected': {
                                backgroundColor: '#EBF5EB !important',
                                '&:hover': {
                                    backgroundColor: '#D7EAD8 !important'
                                }
                            }
                        },
                    },
                }}
                labelId="demo-simple-select-label"
                id="permission-select"
                value={permission}
                label="Permission"
                onChange={handleChange}
                renderValue={(selected) => {
                    return <ModifiedMenuItem showCheck={false} color='rgba(0, 0, 0, 0.84)' name={selected} showIcon />
                }}
            >
                <MenuItem
                    value={'VIEW'}
                    sx={{ backgroundColor: getBackgroundColor('VIEW'), border: shouldShowCheck('VIEW') ? null : '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '4px' }}
                    onClick={() => setMenuColors(prev => {
                        return {
                            VIEW: true,
                            EDIT: false,
                            SHARE: false,
                            OWNER: false
                        }
                    })}
                >

                    <ModifiedMenuItem showCheck={shouldShowCheck('VIEW')} color={getColor('VIEW')} name={'VIEW'} showIcon />
                </MenuItem>
                <MenuItem
                    value={'EDIT'}
                    sx={{ backgroundColor: getBackgroundColor('EDIT'), border: shouldShowCheck('EDIT') ? null : '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '4px' }}
                    onClick={() => setMenuColors(prev => {
                        return {
                            VIEW: true,
                            EDIT: true,
                            SHARE: false,
                            OWNER: false
                        }
                    })}
                >
                    <ModifiedMenuItem showCheck={shouldShowCheck('EDIT')} color={getColor('EDIT')} name={'EDIT'} showIcon />
                </MenuItem>
                <MenuItem
                    value={'SHARE'}
                    sx={{ backgroundColor: getBackgroundColor('SHARE'), border: shouldShowCheck('SHARE') ? null : '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '4px' }}
                    onClick={() => setMenuColors(prev => {
                        return {
                            VIEW: true,
                            EDIT: true,
                            SHARE: true,
                            OWNER: false
                        }
                    })}
                >
                    <ModifiedMenuItem showCheck={shouldShowCheck('SHARE')} color={getColor('SHARE')} name={'SHARE'} showIcon />
                </MenuItem>
                {/* <MenuItem
                    value={'OWNER'}
                    // disabled
                    sx={{ backgroundColor: getBackgroundColor('OWNER') }}
                    onClick={() => setMenuColors(prev => {
                        return {
                            VIEW: true,
                            EDIT: true,
                            SHARE: true,
                            OWNER: true
                        }
                    })}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                            width: '100%',
                            position: 'relative'
                        }}
                    >
                        <Stack direction="row" alignItems="center" spacing={'6px'}>
                            <ManageAccountsOutlined sx={{
                                width: 20,
                                height: 20,
                                color: getColor('OWNER')
                            }} />
                            <Box sx={{ color: getColor('OWNER') }}>Owner</Box>
                        </Stack>
                        {shouldShowCheck('OWNER') && (
                            <CheckOutlined sx={{
                                width: 20,
                                height: 20,
                                position: 'absolute',
                                right: 0,
                                color: '#215924'
                            }} />
                        )}
                    </Stack>
                </MenuItem> */}
            </Select>
        </FormControl >
    );
}