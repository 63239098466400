import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
    IconButton,
    Stack,
    TextField,
    Snackbar,
    Alert,
} from "@mui/material";
import { CloseOutlined, WarningAmberOutlined } from '@mui/icons-material';
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import * as EmailValidator from "email-validator";

// project import
import store from "../../store/store";
import { getSubscriptionData, subscriptionAddUsers } from "../../store/reducers/userSlice";
import VirtualizedAutocomplete from "./VirtualizedAutocomplete";
import { openAddUserToSubscriptionDialog } from "../../store/reducers/dialogSlice";

export default function AddUserToSubscriptionDialog() {
    const open = useSelector((state) => state.dialogsOpen.addUserToSubscription.value);
    const subscription = useSelector((state) => state.user.subscription);
    const contacts = useSelector((state) => state.contacts.contacts);
    const shareableUsers = useSelector((state) => state.user.shareableUsers);
    const subscriptionAddUsersStatus = useSelector((state) => state.user.subscriptionAddUsersStatus);
    const loading = subscriptionAddUsersStatus === 'loading';
    const [emailError, setEmailError] = useState(false);
    const [emailsValues, setEmailsValues] = useState([]);
    const [textValue, setTextValue] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);// Snack bar management
    const handleSnackbarOpen = () => setSnackbarOpen(true);
    const handleSnackbarClose = () => setSnackbarOpen(false);
    const deletedUsers = subscription.deleted_users ? subscription.deleted_users.map(user => {
        const contact = contacts.find(contact => Array.isArray(contact.emailAddresses) && contact.emailAddresses.some(emailObj => emailObj.value === user.email));
        const name = contact ? contact.name?.displayName : null;
        return { ...user, id: user.email, name }
    }) : [];
    const restrictedUsers = subscription.restricted_users ? subscription.restricted_users.map(user => ({
        ...user,
        id: user.email
    })) : [];
    let unlicensedUsers = shareableUsers.users.reduce((acc, user) => {
        return subscription.licensed_users?.some(licensedUser => licensedUser.email === user.email) ? acc : [...acc, {
            ...user,
            id: user.email
        }];
    }, []);
    const uniqueRestrictedUsers = restrictedUsers.filter(restrictedUser => {
        return !unlicensedUsers.some(unlicensedUser => unlicensedUser.email === restrictedUser.email);
    });
    unlicensedUsers = [...unlicensedUsers, ...uniqueRestrictedUsers];
    const uniqueDeletedUsers = deletedUsers.filter(deletedUser => {
        return !unlicensedUsers.some(unlicensedUser => unlicensedUser.email === deletedUser.email);
    });
    unlicensedUsers = [...unlicensedUsers, ...uniqueDeletedUsers];
    const handleClose = () => {
        store.dispatch(openAddUserToSubscriptionDialog(false));
        setEmailsValues([]);
        setTextValue('');
    };
    const handleAddUserClick = async () => {
        let hasError = false;
        if (textValue.length > 0) {
            if (!emailsValues.find((value) => value.email === textValue)) {
                emailsValues.push({ email: textValue });
            }
            const performedValues = emailsValues.map((item) =>
                item.email ? item : { email: item }
            );
            const uniqueValues = performedValues.filter(
                (obj, idx, arr) =>
                    idx === arr.findIndex((t) => t.email === obj.email)
            );
            const newValue = uniqueValues.map((option) => {
                const newOption = option;
                let error = false;
                if (!EmailValidator.validate(newOption.email)) {
                    error = true;
                    hasError = true;
                    setEmailError(true);
                }
                newOption.error = error;
                return newOption;
            });
            setEmailsValues(newValue);
            setTextValue("");
        } else {
            const uniqueValues = emailsValues.filter(
                (obj, idx, arr) =>
                    idx === arr.findIndex((t) => t.email === obj.email)
            );
            const newValue = uniqueValues.map((option) => {
                const newOption = option;
                let error = false;
                if (!EmailValidator.validate(newOption.email)) {
                    error = true;
                    hasError = true;
                    setEmailError(true);
                }
                newOption.error = error;
                return newOption;
            });
            setEmailsValues(newValue);
            setTextValue("");
        }
        if (!hasError && emailsValues.length > 0) {
            const emails = emailsValues.map(item => item.email);
            await store.dispatch(subscriptionAddUsers(emails));
            handleClose();
            store.dispatch(getSubscriptionData());
        } else if (emailsValues.length > 0) {
            handleSnackbarOpen();
        }
    };
    const isDisabled = () => {
        return subscription.status === 2 || subscription.status === 0
            || subscription.quantity === subscription.active_quantity;
    }

    const rows = unlicensedUsers;


    const onInputChange = (newInputValue) => {
        setTextValue(newInputValue);
        if (newInputValue.trim() === "") {
            setEmailError(false);
            return;
        }
        setEmailError(false);
    };
    const onChange = (event, values) => {
        const preparedValues = values.reduce((acc, item) => {
            if (item.email) {
                return [...acc, item];
            }
            if (typeof item === 'string' && item.includes(' ')) {
                const emails = item
                    .split(' ')
                    .filter(subItem => subItem.trim() !== '') // Exclude lines containing only spaces
                    .map(subItem => ({ email: subItem }));
                return [...acc, ...emails];
            }
            return [...acc, { email: item }];
        }, []);

        const uniqueValues = preparedValues.filter(
            (obj, idx, arr) =>
                idx === arr.findIndex((t) => t.email === obj.email)
        );
        const noLicensedUsers = [];
        const newValue = uniqueValues.map((option) => {
            const newOption = { ...option };
            let error = false;
            if (!EmailValidator.validate(newOption.email)) {
                error = true;
            } else {
                let contact = subscription.licensed_users.find(
                    (el) => el.email === newOption.email
                );
                if (!contact) {
                    contact = shareableUsers.users.find(
                        (el) => el.email === newOption.email && el.domainUser
                    );
                    if (!contact) {
                        if (!newOption.group) {
                            noLicensedUsers.push(newOption.email);
                        }
                    }
                }
            }
            newOption.error = error;
            return newOption;
        });
        const hasError = newValue.some(item => item.error === true);
        setEmailError(hasError);
        setEmailsValues(newValue);

    };

    const filterOptions = (options, { inputValue }) => {
        const loweredInputValue = inputValue.toLowerCase();
        return options.filter(
            (option) =>
                (option.name &&
                    option.name.toLowerCase().includes(loweredInputValue)) ||
                (option.email &&
                    option.email.toLowerCase().includes(loweredInputValue))
        );
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        width: 840,
                        maxWidth: 840,
                        minWidth: 444,
                        backgroundColor: 'rgba(59, 125, 237, 1)',
                    }
                }}
            >
                <DialogTitle sx={{ backgroundColor: 'rgba(59, 125, 237, 1)' }}>
                    <Grid container sx={{ alignItems: 'center' }}>
                        <Grid item>
                            <Typography
                                variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Add users</Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 12,
                        top: 12,
                        color: 'rgba(255, 255, 255, 0.54)',
                        '&:hover': {
                            color: 'rgba(255, 255, 255, 0.87)',
                            backgroundColor: 'rgba(0, 0, 0, 0.16)'
                        },
                    }}
                >
                    <CloseOutlined />
                </IconButton>
                <DialogContent dividers sx={{ border: 0, backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                    <Stack spacing={3}>
                        <Box
                            sx={{
                                width: 'auto',
                            }}>
                            <Typography
                                noWrap
                                sx={{
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    fontSize: 16,
                                    fontFamily: `"Roboto"`,
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '24px',
                                    letterSpacing: '0.2px',
                                }}
                            >
                                Add users to your team automatically by sharing your contacts, or manually invite them here.
                            </Typography>
                        </Box>
                        <Stack spacing={2}>
                            <VirtualizedAutocomplete
                                options={rows}
                                emailError={emailError}
                                onChange={onChange}
                                emailsValues={emailsValues}
                                setEmailsValues={setEmailsValues}
                                textValue={textValue}
                                onInputChange={(event, newInputValue) =>
                                    onInputChange(newInputValue)
                                }
                                filterOptions={filterOptions}
                                setTextValue={setTextValue}
                                setEmailError={setEmailError}
                            />
                            <Box
                                sx={{
                                    width: 'auto',
                                }}>
                                <Typography
                                    noWrap
                                    sx={{
                                        color: 'rgba(0, 0, 0, 0.54)',
                                        fontSize: 16,
                                        fontFamily: `"Roboto"`,
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '0.2px',
                                    }}
                                >
                                    Enter one or more users from your domain, domain user groups, or @gmail accounts.
                                </Typography>
                            </Box>
                        </Stack>
                        {/* <StyledTextField
                            sx={{
                                width: '100%',
                                height: 42,
                                display: "flex",
                                justifyContent: "center",
                            }}
                            onChange={(event) => {
                                setSearchUsersFilter(event.target.value);
                            }}
                            value={searchUsersFilter}
                            id="input-with-icon-textfield"
                            placeholder="Add individual or group emails"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" sx={{ marginLeft: '-3px', }}>
                                        <SearchOutlined sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    searchUsersFilter && <InputAdornment position="end">
                                        <CloseOutlined
                                            sx={{
                                                width: 20,
                                                height: 20,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setSearchUsersFilter('');
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                                style: {
                                    height: '42px',
                                    width: '100%',
                                    color: 'rgba(32, 33, 36, 1)',
                                    fontSize: '16px',
                                    fontFamily: 'Open Sans',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '46px',
                                    letterSpacing: '-0.32px',
                                }
                            }}
                        /> */}
                        {/* <Box
                            sx={{
                                width: '100%',
                                height: '452px',
                            }}
                        >
                            <StyledDataGrid
                                rows={rows}
                                columns={columns}
                                columnHeaderHeight={56}
                                rowHeight={72}
                                autoPageSize
                                checkboxSelection
                                disableColumnMenu
                                disableColumnSelector
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    setRowSelectionModel(newRowSelectionModel);
                                }}
                                rowSelectionModel={rowSelectionModel}
                                sortModel={sortModel}
                                onSortModelChange={(model) => setSortModel(model)}
                            />
                        </Box> */}
                    </Stack>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={3000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        sx={{
                            position: 'absolute',
                            left: '50%',
                            top: '85%',
                            transform: 'translateX(-50%)',
                        }}
                    >
                        <Alert onClose={handleSnackbarClose} severity="error" icon={<WarningAmberOutlined sx={{ width: 22, height: 22 }} />}>
                            <Typography
                                sx={{
                                    color: '#541313',
                                    fontWeight: 500,
                                    fontSize: 14,
                                    fontFamily: 'Roboto',
                                    fontStyle: 'normal',
                                    lineHeight: '143%',
                                    letterSpacing: '0.15px',
                                }}
                            >
                                Enter only valid email addresses
                            </Typography>
                        </Alert>
                    </Snackbar>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                    <Button onClick={handleClose}>
                        <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
                    </Button>
                    <LoadingButton
                        loading={loading}
                        // disabled={isDisabled()}
                        disabled={loading}
                        sx={{
                            color: emailsValues.length === 0 ? 'rgba(0, 0, 0, 0.32)' : 'rgba(59, 125, 237, 1)'
                        }}
                        onClick={handleAddUserClick}
                    >
                        <Typography
                            variant={'dialogButton'}
                            sx={{
                                color: loading ? 'rgba(0, 0, 0, 0.32)' : 'rgba(59, 125, 237, 1)'
                            }}
                        >
                            Add users
                        </Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

const StyledTextField = styled(TextField)(() => ({
    borderRadius: 8,
    backgroundColor: '#F1F3F4',
    '.MuiInput-root': {
        height: '42px',
    },
    '& label.Mui-focused': {
        color: '#A0AAB4',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#F1F3F4',
            borderRadius: 8,
        },
        '&:hover fieldset': {
            borderColor: '#F1F3F4',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#F1F3F4',
        },
    },
}));

const getUsersByFilter = (users, filter) => {
    return users.filter(item => item.name?.toLowerCase().includes(filter && filter.toLowerCase()) || item.email.toLowerCase().includes(filter && filter.toLowerCase()));
}
