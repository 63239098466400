import * as React from 'react';
import {
    Badge,
    Box, Chip,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Stack,
    SvgIcon,
    Tooltip,
    Typography,
    AvatarGroup, Paper, MenuList,
    Menu,
    Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
    Clear,
    DeleteOutlineOutlined,
    RemoveCircleOutlined,
    MoreVertOutlined,
    BookmarkAddOutlined, StarOutlined,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useMemo, useState } from "react";
import { gridStringOrNumberComparator } from '@mui/x-data-grid';

// project import
import { StyledDataGrid } from "../StyledDataGrid";
import { setManageUsersFilter } from "../../store/reducers/dataSlice";
import {
    setLicensesToDelete,
    setSelectedUsers,
    subscriptionAddRestrictions,
    subscriptionRemoveRestrictions,
    setUsersToDelete,
    setUsersToRemoveAdminPermissions,
    setUsersToAddAdminPermissions,
    setUsersToAddToSubscription,
} from "../../store/reducers/userSlice";
import {
    openAlertDeleteLicenseDialog,
    openAlertAddUserToSubscriptionDialog,
    openDeleteUserFromManageUsersDialog, openRemoveAdminPermissionsDialog, openAddAdminPermissionsDialog
} from "../../store/reducers/dialogSlice";
import { ALERT_HEIGHT, BREADCRUMBS_HEIGHT, HEADER_HEIGHT, MANAGE_USERS_APPBAR_HEIGHT } from "../../constants/size";
import { LicensedUsersIcon, NoLicensedUsersIcon } from "./LicenseIcons";
import CustomLoadingOverlay from "../CustomLoadingOverlay";
import { UserAvatar } from "../UserAvatar";

// Modify the StyledDataGrid component, adding rules for selected elements
const StyledDataGridWithHiddenCheckboxes = styled(StyledDataGrid)(({ theme }) => ({
    '& .MuiDataGrid-columnHeaderDraggableContainer[data-field="__check__"]': {
        width: '0px !important',
        padding: '0 !important',
        border: 'none !important',
    },
    '& .avatar-checkbox-container': {
        position: 'relative',
        width: 40,
        height: 40,
        zIndex: 10,
    },
    '& .avatar-container': {
        transition: 'opacity 0.2s',
    },
    '& .checkbox-container': {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0,
        transition: 'opacity 0.2s',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
    },
    '& .MuiDataGrid-row.checkbox-hovered': {
        backgroundColor: 'transparent !important',
    },
    '& .MuiDataGrid-row:hover:not(.checkbox-hovered) .avatar-container': {
        opacity: 0,
    },
    '& .MuiDataGrid-row:hover:not(.checkbox-hovered) .checkbox-container': {
        opacity: 1,
    },
    '& .checkbox-hovered .checkbox-container': {
        opacity: 1,
        backgroundColor: '#F5F5F5',
        borderRadius: '50%',
    },
    '& .checkbox-hovered .avatar-container': {
        opacity: 0,
    },
    '& .selected-row .avatar-container': {
        opacity: 0,
        transition: 'opacity 0s',
    },
    '& .selected-row .checkbox-container': {
        opacity: 1,
        transition: 'opacity 0s',
    },
    '& .selected-row .checkbox-container:hover': {
        backgroundColor: '#BECFE8 !important',
        borderRadius: '50%',
    },
    '& .avatar-checkbox-container:hover ~ .MuiDataGrid-cell': {
        backgroundColor: 'transparent !important',
    },
    '& .selected-checkbox': {
        backgroundColor: '#D1E1FA !important',
        borderRadius: '50%',
    },
    '& .MuiDataGrid-row:hover .MuiDataGrid-cell.selected-row': {
        backgroundColor: '#C6D9F7 !important',
    },
    '& .MuiDataGrid-row:hover .selected-checkbox': {
        backgroundColor: '#C6D9F7 !important',
        borderRadius: '50%',
    },
}));

// Update the NameColumnHeader component
const NameColumnHeader = ({ params, selectedUsers, rows }) => {
    const dispatch = useDispatch();
    const [isHeaderHovered, setIsHeaderHovered] = useState(false);

    const isIndeterminate = selectedUsers.length > 0 && selectedUsers.length < rows.length;
    const isChecked = selectedUsers.length > 0 && selectedUsers.length === rows.length;

    return (
        <Stack
            direction="row"
            spacing={'20px'} // Add the same spacing as in renderCell
            alignItems="center"
            onMouseEnter={() => setIsHeaderHovered(true)}
            onMouseLeave={() => setIsHeaderHovered(false)}
            sx={{ width: '100%', height: '100%', cursor: 'pointer' }}
        >
            <Box sx={{ width: 40, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {(selectedUsers.length > 0 || isHeaderHovered) ? (
                    <Checkbox
                        indeterminate={isIndeterminate}
                        checked={isChecked}
                        onChange={(event) => {
                            // If not all rows are selected or all are selected - deselect
                            if (selectedUsers.length > 0) {
                                dispatch(setSelectedUsers([]));
                            } else {
                                // If nothing is selected - select all rows
                                dispatch(setSelectedUsers(rows.map(row => row.id)));
                            }
                        }}
                        onClick={(e) => e.stopPropagation()}
                        sx={{ padding: 0 }}
                    />
                ) : null}
            </Box>

            {selectedUsers.length > 0 ? (
                <Typography sx={{
                    color: '#3B7DED',
                    fontFamily: 'Open Sans',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '24px',
                    letterSpacing: '0',
                }}>Selected: {selectedUsers.length}</Typography>
            ) : (
                <span>{params.colDef.headerName}</span>
            )}
        </Stack>
    );
};

export default function ManageUsers() {
    const dispatch = useDispatch();
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const noLicenseAlert = useSelector((state) => state.user.noLicenseAlert);
    const showAlert = domainInstallAlert || noLicenseAlert;
    const subscription = useSelector((state) => state.user.subscription);
    const shareableUsers = useSelector((state) => state.user.shareableUsers);
    const selectedUsers = useSelector((state) => state.user.selectedUsers);
    const user = useSelector((state) => state.user);
    const contacts = useSelector((state) => state.contacts.contacts);
    const manageUsersFilter = useSelector((state) => state.data.manageUsersFilter);
    const statusFirstLoad = useSelector((state) => state.contacts.statusFirstLoad);
    const userInitialized = useSelector((state) => state.user.userInitialized);
    const domainInfoInitialized = useSelector((state) => state.user.domainInfoInitialized);
    const groups = useSelector((state) => state.groups);
    const [sortModel, setSortModel] = React.useState([
        {
            field: 'name',
            sort: 'asc'
        }
    ]);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const slots = useMemo(() => ({
        columnMenu: CustomColumnMenu,
        loadingOverlay: CustomLoadingOverlay,
    }), []);

    if (!userInitialized || !domainInfoInitialized) return null; // Waiting for Alerts information to prevent table drift

    const handleRemoveLicenseClick = (event, user) => {
        event.stopPropagation();
        dispatch(setLicensesToDelete([user]));
        dispatch(openAlertDeleteLicenseDialog(true));
    };
    const handleRemoveSelectedLecensesClick = () => {
        const licensesToDelete = selectedUsers.reduce((acc, item) => {
            const user = rows.find(user => user.id === item);
            if (user) {
                acc.push(user);
            }
            return acc;
        }, []);
        dispatch(setLicensesToDelete(licensesToDelete));
        dispatch(openAlertDeleteLicenseDialog(true));
    };
    const handleAddUserToSubscriptionClick = async (event, user) => {
        event.stopPropagation();
        dispatch(setUsersToAddToSubscription([user]));
        dispatch(openAlertAddUserToSubscriptionDialog(true));
    }
    const handleAddSelectedUsersToSubscriptionClick = async () => {
        const licensesToAdd = selectedUsers.reduce((acc, item) => {
            const user = rows.find(user => user.id === item);
            return user ? [...acc, user] : acc;
        }, []);
        dispatch(setUsersToAddToSubscription(licensesToAdd));
        dispatch(openAlertAddUserToSubscriptionDialog(true));
    };
    const handleExterminateUserClick = (event, user) => {
        event.stopPropagation();
        dispatch(setUsersToDelete([user]));
        dispatch(openDeleteUserFromManageUsersDialog(true));
    }
    const handleExterminateSelectedUsersClick = () => {
        const usersToDelete = selectedUsers.reduce((acc, item) => {
            const user = rows.find(user => user.id === item);
            if (user) {
                acc.push(user);
            }
            return acc;
        }, []);
        dispatch(setUsersToDelete(usersToDelete));
        dispatch(openDeleteUserFromManageUsersDialog(true));
    }
    const handleRemoveAdminPermissionClick = (event, user) => {
        event.stopPropagation();
        dispatch(setUsersToRemoveAdminPermissions({ selectedUsers: [user], rowsUsers: rows }));
        dispatch(openRemoveAdminPermissionsDialog(true));
    }
    const handleRemoveSelectedAdminsPermissionsClick = () => {
        const users = selectedUsers.reduce((acc, item) => {
            const user = rows.find(user => user.id === item);
            if (user) {
                acc.push(user);
            }
            return acc;
        }, []);
        dispatch(setUsersToRemoveAdminPermissions({ selectedUsers: users, rowsUsers: rows }));
        dispatch(openRemoveAdminPermissionsDialog(true));
    }

    const handleAddAdminPermissionClick = (event, user) => {
        event.stopPropagation();
        dispatch(setUsersToAddAdminPermissions([user]));
        dispatch(openAddAdminPermissionsDialog(true));
    }
    const handleAddSelectedAdminsPermissionsClick = () => {
        const users = selectedUsers.reduce((acc, item) => {
            const user = rows.find(user => user.id === item);
            if (user) {
                acc.push(user);
            }
            return acc;
        }, []);
        dispatch(setUsersToAddAdminPermissions(users));
        dispatch(openAddAdminPermissionsDialog(true));
    }

    const handleAddToRestrictedClick = (event, user) => {
        event.stopPropagation();
        dispatch(subscriptionAddRestrictions([user.email]));
    };
    const handleDeleteFromRestrictedClick = (event, user) => {
        event.stopPropagation();
        dispatch(subscriptionRemoveRestrictions([user.email]));
    };
    const customSortComparator = (v1, v2, param1, param2) => {
        // Sort by isAdmin
        if (v1.isAdmin !== v2.isAdmin) {
            return v1.isAdmin ? -1 : 1; // Administrators go first
        }

        // If both are the same by isAdmin, sort by license
        if (v1.license !== v2.license) {
            return v1.license === "Licensed" ? -1 : 1; // First with license
        }

        // If both are the same by isAdmin and license, sort by name
        return gridStringOrNumberComparator(v1.name, v2.name, param1, param2);
    };
    const getPhotoFormContacts = (email) => {
        const contact = contacts.find(contact => Array.isArray(contact.emailAddresses) && contact.emailAddresses.some(emailObj => emailObj.value === email));
        return contact?.photos?.[0]?.url;
    };

    // Add a new function to handle checkbox state changes
    const handleCheckboxChange = (event, id) => {
        event.stopPropagation();
        const newSelection = selectedUsers.includes(id)
            ? selectedUsers.filter(userId => userId !== id)
            : [...selectedUsers, id];
        dispatch(setSelectedUsers(newSelection));
    };

    const columns = [
        {
            field: 'name',
            headerName: 'Name and email',
            minWidth: 250,
            flex: 1,
            sortable: !selectedUsers.length,
            disableColumnMenu: true,
            renderHeader: (params) => (
                <NameColumnHeader
                    params={params}
                    selectedUsers={selectedUsers}
                    rows={rows}
                />
            ),
            renderCell: params => {
                return (
                    <Stack direction={'row'} spacing={'20px'} sx={{ alignItems: 'center' }}>
                        <AvatarWithCheckbox
                            params={params}
                            selected={selectedUsers.includes(params.row.id)}
                            onCheckboxChange={handleCheckboxChange}
                            getPhotoFormContacts={getPhotoFormContacts}
                        />
                        <Stack>
                            <Stack direction={'row'} spacing={'4px'} sx={{ alignItems: 'center' }}>
                                <Typography
                                    sx={{
                                        color: "rgba(0, 0, 0, 0.87)",
                                        fontSize: 14,
                                        fontFamily: "Roboto",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "20px",
                                        letterSpacing: "0.2px",
                                    }}
                                >
                                    {params.value || params.row.email}
                                </Typography>
                                {params.row.isAdmin && <Tooltip title={'Admin'}><StarOutlined sx={{ width: 18, height: 18, color: "rgba(0, 0, 0, 0.54)" }} /></Tooltip>}
                            </Stack>
                            <Typography
                                sx={{
                                    color: "rgba(0, 0, 0, 0.54)",
                                    fontSize: 12,
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "16px",
                                    letterSpacing: "0.2px",
                                }}
                            >
                                {params.row.email}
                            </Typography>
                        </Stack>
                    </Stack>
                )
            }
        },
        {
            field: 'license',
            headerName: 'License',
            minWidth: 100,
            flex: 1,
            sortable: !selectedUsers.length,
            renderHeader: (params) => (
                selectedUsers.length ? <></> : <span>
                    {params.colDef.headerName}
                </span>
            ),
            renderCell: params => (
                params.value === 'Licensed' ? <LicensedUsersIcon /> : <NoLicensedUsersIcon />
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 150,
            flex: 1,
            sortable: false,
            renderHeader: (params) => (
                selectedUsers.length ? <></> : <span>
                    {params.colDef.headerName}
                </span>
            ),
            renderCell: (params) => {
                return (
                    <Stack
                        direction={"row"}
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                    >
                        {getUserStatusIcon(params.value)}
                        <Typography
                            sx={{
                                color: "rgba(0, 0, 0, 0.87)",
                                fontSize: 14,
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "20px",
                                letterSpacing: "0.2px",
                            }}
                        >
                            {getUserStatusText(params.value)}
                            {/* {getSyncStatusText(status)} */}
                        </Typography>
                    </Stack>
                );
            }
        },
        {
            field: 'sharedBy',
            headerName: 'Shared by',
            minWidth: 200,
            flex: 1,
            sortable: !selectedUsers.length,
            disableColumnMenu: true,
            renderHeader: (params) => (
                selectedUsers.length ? <></> : <span>
                    {params.colDef.headerName}
                </span>
            ),
            renderCell: params => {
                return <AvatarSharedBy sharedUsers={params.value} />
            }
        },
        {
            field: 'domainGroups',
            headerName: 'Domain groups',
            minWidth: 250,
            flex: 1,
            sortable: !selectedUsers.length,
            disableColumnMenu: true,
            renderHeader: (params) => (
                selectedUsers.length ? <></> : <span>
                    {params.colDef.headerName}
                </span>
            ),
            renderCell: params => {
                let tooltipText;
                if (params.value?.length > 0) {
                    tooltipText = params.value.slice(1).map(item => item.domain_group_name).join('\n');
                }

                return (
                    <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
                        <Stack>
                            <Box sx={{ maxWidth: 190 }}>
                                <Typography
                                    noWrap
                                    sx={{
                                        color: "rgba(0, 0, 0, 0.87)",
                                        fontSize: 14,
                                        fontFamily: "Roboto",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "20px",
                                        letterSpacing: "0.2px",
                                    }}
                                >
                                    {params.value?.[0]?.domain_group_name}
                                </Typography>
                            </Box>
                            {params.value?.length ? (
                                <Box sx={{ maxWidth: 190 }}>
                                    <Typography
                                        noWrap
                                        sx={{
                                            color: "rgba(0, 0, 0, 0.54)",
                                            fontSize: 12,
                                            fontFamily: "Roboto",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "16px",
                                            letterSpacing: "0.2px",
                                        }}
                                    >
                                        {params.value?.[0]?.domain_group_email}
                                    </Typography>
                                </Box>
                            ) : null}
                        </Stack>
                        {params.value?.length > 1 ? (
                            <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{tooltipText}</span>}>
                                <Chip
                                    sx={{
                                        marginRight: 1,
                                        maxWidth: 132,
                                        border: '1px solid rgba(0, 0, 0, 0.12)',
                                        borderRadius: '8px'
                                    }}
                                    label={
                                        <Typography noWrap variant={'label'}>{`+${params.value?.length - 1}`}</Typography>
                                    }
                                    size="medium"
                                    variant={'outlined'}
                                    color={'default'}
                                />
                            </Tooltip>
                        ) : null}
                    </Stack>
                )
            }
        },
        {
            field: 'memberShips',
            headerName: 'Labels',
            minWidth: 430,
            flex: 1,
            sortable: !selectedUsers.length,
            disableColumnMenu: true,
            renderHeader: (params) => (
                selectedUsers.length ? <Box sx={{ minWidth: 430, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <Tooltip
                        placement="bottom"
                        title='Appoint admin'
                    >
                        <IconButton
                            onClick={() => handleAddSelectedAdminsPermissionsClick()}
                        >
                            <SvgIcon>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.81 8.62L22 9.24L19.9721 11H17.5367L18.09 10.52L13.71 10.14L12 6.1L10.3 10.13L5.92 10.51L9.24 13.39L8.24 17.67L11 16.0037V17.8736L5.82 21L7.46 13.97L2 9.24L9.19 8.63L12 2L14.81 8.62Z" fill="#757575" />
                                    <path d="M21 18H18V21H16V18H13V16H16V13H18V16H21V18Z" fill="#757575" />
                                </svg>
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        title='Remove admin'
                    >
                        <IconButton
                            onClick={() => handleRemoveSelectedAdminsPermissionsClick()}
                        >
                            <SvgIcon>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.28961 7.69961L2.09961 3.50961L3.51961 2.09961L21.8996 20.4896L20.4896 21.8996L6.28961 7.69961Z" fill="#757575" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.43439 8.05338L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27L18.18 21L16.9119 15.5309L14.7759 13.3949L14.77 13.4L15.77 17.68L12 15.4L8.24 17.67L9.24 13.39L5.92 10.51L10.3 10.13L10.6593 9.27827L9.43439 8.05338ZM13.2437 9.03838L12 6.1L11.4975 7.29121L10.2756 6.06863L12 2L14.81 8.62L22 9.24L17.4189 13.2159L16.2874 12.0837L18.09 10.52L14.405 10.2003L13.2437 9.03838Z" fill="#757575" />
                                </svg>
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        title='Add license'
                    >
                        <IconButton
                            onClick={() => handleAddSelectedUsersToSubscriptionClick()}
                        >
                            <BookmarkAddOutlined />
                        </IconButton>
                    </Tooltip>
                </Box> : <span>
                    {params.colDef.headerName}
                </span>
            ),
            valueGetter: (params) => params.row.groups,
            renderCell: (params) => {
                let tooltipTextArray;
                if (params.value?.length > 3) {
                    tooltipTextArray = params.value.slice(2);
                }
                return (
                    params.value?.slice(0, 3).map((item, index) => (
                        <Tooltip
                            key={index}
                            title={
                                (index === 2 && params.value.length > 3)
                                    ? <Box sx={{ maxWidth: 300 }}>
                                        {tooltipTextArray.map((text, idx) => (
                                            <span
                                                key={idx}
                                                style={{
                                                    display: 'block',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                {text}
                                            </span>
                                        ))}
                                    </Box>
                                    : ''
                            }
                        >
                            <Chip
                                sx={{
                                    marginRight: 1,
                                    maxWidth: 132,
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRadius: '8px'
                                }}
                                label={
                                    (index === 2 && params.value.length > 3) ? (
                                        <Typography variant={'label'}>+{params.value.length - 2}</Typography>
                                    ) : (
                                        <Typography noWrap variant={'label'}>{item}</Typography>
                                    )
                                }
                                size="medium"
                                variant={'outlined'}
                                color={'default'}
                            />
                        </Tooltip>
                    ))
                );
            }
        },
        {
            field: 'action',
            headerName: '',
            width: 212,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: (params) => (
                selectedUsers.length ? <Stack direction={'row'} spacing={0}>
                    <Tooltip
                        placement="bottom"
                        title='Appoint admin'
                    >
                        <span>
                            <IconButton
                                onClick={() => handleAddSelectedAdminsPermissionsClick()}
                            >
                                <SvgIcon>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M14.81 8.62L22 9.24L19.9721 11H17.5367L18.09 10.52L13.71 10.14L12 6.1L10.3 10.13L5.92 10.51L9.24 13.39L8.24 17.67L11 16.0037V17.8736L5.82 21L7.46 13.97L2 9.24L9.19 8.63L12 2L14.81 8.62Z"
                                            fill="#757575" />
                                        <path d="M21 18H18V21H16V18H13V16H16V13H18V16H21V18Z" fill="#757575" />
                                    </svg>
                                </SvgIcon>
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        title='Remove admin'
                    >
                        <span>
                            <IconButton
                                onClick={() => handleRemoveSelectedAdminsPermissionsClick()}
                            >
                                <SvgIcon>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.28961 7.69961L2.09961 3.50961L3.51961 2.09961L21.8996 20.4896L20.4896 21.8996L6.28961 7.69961Z"
                                            fill="#757575" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.43439 8.05338L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27L18.18 21L16.9119 15.5309L14.7759 13.3949L14.77 13.4L15.77 17.68L12 15.4L8.24 17.67L9.24 13.39L5.92 10.51L10.3 10.13L10.6593 9.27827L9.43439 8.05338ZM13.2437 9.03838L12 6.1L11.4975 7.29121L10.2756 6.06863L12 2L14.81 8.62L22 9.24L17.4189 13.2159L16.2874 12.0837L18.09 10.52L14.405 10.2003L13.2437 9.03838Z"
                                            fill="#757575" />
                                    </svg>
                                </SvgIcon>
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        title='Add license'
                    >
                        <IconButton
                            onClick={() => handleAddSelectedUsersToSubscriptionClick()}
                        >
                            <BookmarkAddOutlined />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        title='Remove license'
                    >
                        <IconButton
                            onClick={() => handleRemoveSelectedLecensesClick()}
                        >
                            <SvgIcon>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.58063 8.41055L1.39062 4.22055L2.81062 2.81055L21.1906 21.2005L19.7806 22.6105L5.58063 8.41055Z" fill="#757575" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5 5.01937L5.58063 5.6L7 7.01937V18L12 15.82L17 18V17.0194L19 19.0194V21L12 18L5 21V5.01937ZM19 16.1982V5C19 3.9 18.1 3 17 3H7C6.6569 3 6.33325 3.08756 6.0503 3.24144L7.80791 5H17V14.1971L19 16.1982Z" fill="#757575" />
                                </svg>
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        title='Delete user'
                    >
                        <span>
                            <IconButton
                                onClick={() => handleExterminateSelectedUsersClick()}
                            >
                                <DeleteOutlineOutlined
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        flexShrink: 0,
                                    }}
                                />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Stack> : <>
                    {params.colDef.headerName}
                </>
            ),
            renderCell: params => {
                return <Box className="actionIcons">
                    <ActionMenu params={params}
                        handleRemoveLicenseClick={handleRemoveLicenseClick}
                        handleAddUserToSubscriptionClick={handleAddUserToSubscriptionClick}
                        handleExterminateUserClick={handleExterminateUserClick}
                        handleRemoveAdminPermissionClick={handleRemoveAdminPermissionClick}
                        handleAddAdminPermissionClick={handleAddAdminPermissionClick}
                    />
                </Box>
            }
        },
        {
            field: "customSort",
            headerName: "Custom Sort",
            width: 200,
            valueGetter: (params) => {
                if (params.row.isAdmin) return `1_${params.row.name}`;
                if (params.row.license === "Licensed") return `2_${params.row.name}`;
                return `3_${params.row.name}`;
            },
            sortComparator: (v1, v2) => v1.localeCompare(v2),
            sortable: true,
        },
    ];
    const rows = getUsersByFilter(getUsers(subscription, contacts, shareableUsers.users, user, groups.groups), {
        status: manageUsersFilter.status,
        license: manageUsersFilter.license
    });
    return (
        <StyledDataGridWithHiddenCheckboxes
            sx={{
                height: '100%',
                width: '100%',
                "& .MuiDataGrid-cell[data-field='action']": {
                    textAlign: 'right',
                    justifyContent: 'flex-end',
                    width: '100%',
                },
                "& .MuiDataGrid-columnHeader[data-field='action']": {
                    padding: 0,
                },
                "& .MuiDataGrid-columnHeader[data-field='memberShips']": {
                    padding: 0,
                },
            }}
            key={`${windowSize.width}-${windowSize.height}`}
            disableColumnSelector
            rows={rows}
            columns={columns}
            columnHeaderHeight={56}
            rowHeight={56}
            autoPageSize
            checkboxSelection={false}
            disableRowSelectionOnClick
            disableColumnMenu={selectedUsers.length !== 0}
            loading={statusFirstLoad === 'loading'}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'customSort', sort: 'asc' }],
                },
            }}
            getCellClassName={(params) => {
                return selectedUsers.includes(params.id) ? 'selected-row' : '';
            }}
            onRowClick={(params, event) => {
                event.stopPropagation();
                const newSelection = selectedUsers.includes(params.id)
                    ? selectedUsers.filter(userId => userId !== params.id)
                    : [...selectedUsers, params.id];

                dispatch(setSelectedUsers(newSelection));
            }}
            slots={slots}
            slotProps={{
                loadingOverlay: { topOffset: 600, rowHeight: 56 },
            }}
            columnVisibilityModel={{
                sharedBy: false,
                domainGroups: false,
                memberShips: false,
                customSort: false,
            }}
        />
    );
}

const getUserStatusText = (status) => {
    let result = '';
    status = status?.toUpperCase();
    switch (status) {
        case 'ACTIVE':
            result = 'Shared';
            break;
        case 'SHARING':
            result = 'Sharing';
            break;
        case 'REVOKE':
            result = 'Revoked';
            break;
        case 'PENDING':
            result = 'Pending';
            break;
        case 'UNLICENSED':
            result = 'Unlicensed';
            break;
        case 'BANNED':
            result = 'Restricted';
            break;
        default:
            result = '';
    }
    return result;
}

const getUserStatusIcon = (status) => {
    let result = '';
    status = status?.toUpperCase();
    // status = 'UNLICENSED';
    switch (status) {
        case 'ACTIVE':
            result = <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.66602 10.0013C1.66602 5.4013 5.39935 1.66797 9.99935 1.66797C14.5993 1.66797 18.3327 5.4013 18.3327 10.0013C18.3327 14.6013 14.5993 18.3346 9.99935 18.3346C5.39935 18.3346 1.66602 14.6013 1.66602 10.0013ZM8.33333 11.81L13.825 6.31836L15 7.50169L8.33333 14.1684L5 10.835L6.175 9.66003L8.33333 11.81Z" fill="#2E7D32" />
                </svg>
            </SvgIcon>;
            break;
        case 'SHARING':
            result = <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.99935 1.66797C5.39935 1.66797 1.66602 5.4013 1.66602 10.0013C1.66602 14.6013 5.39935 18.3346 9.99935 18.3346C14.5993 18.3346 18.3327 14.6013 18.3327 10.0013C18.3327 5.4013 14.5993 1.66797 9.99935 1.66797Z" fill="#ED9F02" />
                    <path d="M10 5V3.125L7.5 5.625L10 8.125V6.25C12.0687 6.25 13.75 7.93125 13.75 10C13.75 10.6312 13.5937 11.2312 13.3125 11.75L14.225 12.6625C14.7125 11.8938 15 10.9812 15 10C15 7.2375 12.7625 5 10 5ZM10 13.75C7.93125 13.75 6.25 12.0688 6.25 10C6.25 9.36875 6.40625 8.76875 6.6875 8.25L5.775 7.3375C5.2875 8.10625 5 9.01875 5 10C5 12.7625 7.2375 15 10 15V16.875L12.5 14.375L10 11.875V13.75Z" fill="white" />
                </svg>
            </SvgIcon>;
            break;
        case 'REVOKE':
            result = <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.66602 10.0013C1.66602 5.4013 5.39935 1.66797 9.99935 1.66797C14.5993 1.66797 18.3327 5.4013 18.3327 10.0013C18.3327 14.6013 14.5993 18.3346 9.99935 18.3346C5.39935 18.3346 1.66602 14.6013 1.66602 10.0013ZM9.9987 8.8276L12.9904 5.83594L14.1654 7.01094L11.1737 10.0026L14.1654 12.9943L12.9904 14.1693L9.9987 11.1776L7.00703 14.1693L5.83203 12.9943L8.8237 10.0026L5.83203 7.01094L7.00703 5.83594L9.9987 8.8276Z" fill="#D32F2F" />
                </svg>
            </SvgIcon>;
            break;
        case 'PENDING':
            result = <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.9915 1.66675C5.3915 1.66675 1.6665 5.40008 1.6665 10.0001C1.6665 14.6001 5.3915 18.3334 9.9915 18.3334C14.5998 18.3334 18.3332 14.6001 18.3332 10.0001C18.3332 5.40008 14.5998 1.66675 9.9915 1.66675ZM12.7415 13.9251L9.1665 10.3417V5.83341H10.8332V9.65841L13.9248 12.7501L12.7415 13.9251Z" fill="#ED9F02" />
                </svg>
            </SvgIcon>;
            break;
        case 'UNLICENSED':
        case 'WAITING_LICENSE':
            result = <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.99935 1.66797C5.39935 1.66797 1.66602 5.4013 1.66602 10.0013C1.66602 14.6013 5.39935 18.3346 9.99935 18.3346C14.5993 18.3346 18.3327 14.6013 18.3327 10.0013C18.3327 5.4013 14.5993 1.66797 9.99935 1.66797Z" fill="#D32F2F" />
                    <path d="M12.9165 4.75H7.08317C6.4415 4.75 5.9165 5.275 5.9165 5.91667V15.25L9.99984 13.5L14.0832 15.25V5.91667C14.0832 5.275 13.5582 4.75 12.9165 4.75ZM12.9165 13.5L9.99984 12.2283L7.08317 13.5V5.91667H12.9165V13.5Z" fill="white" />
                </svg>
            </SvgIcon>;
            break;
        case 'BANNED':
            result = <RemoveCircleOutlined color={"error"} />;
            break;
        default:
            result = '';
    }
    return result;
}

const getUsersByFilter = (users, filter) => {
    let filteredUsers = users;
    if (filter.status) {
        if (filter.status === 'Unlicensed') {
            filteredUsers = users.filter(user => user.status === 'UNLICENSED' || user.status === 'WAITING_LICENSE');
        } else {
            filteredUsers = users.filter(user => user.status?.toUpperCase() === filter.status?.toUpperCase());
        }
    }
    if (filter.license) {
        filteredUsers = filteredUsers.filter(user => user.license === filter.license);
    }
    return filteredUsers;
}

function CustomColumnMenu(props) {
    const dispatch = useDispatch();
    const manageUsersFilter = useSelector((state) => state.data.manageUsersFilter);
    const itemProps = {
        colDef: props.colDef,
        hideMenu: props.hideMenu,
    };
    const statuses = [
        { status: 'Active', name: 'Shared ' },
        { status: 'Sharing', name: 'Sharing' },
        { status: 'Revoke', name: 'Revoked' },
        { status: 'Pending', name: 'Pending' },
        { status: 'Unlicensed', name: 'Unlicensed' }];
    const licenses = ['Licensed', 'Unlicensed'];
    return (
        <>
            {itemProps.colDef.field === 'status' ? (
                [...statuses.map(status => <StatusMenuComponent key={uuidv4()} {...itemProps} status={status} />),
                <><Divider /><MenuItem onClick={(event) => {
                    props.hideMenu(event);
                    dispatch(setManageUsersFilter({
                        license: manageUsersFilter.license ?? null,
                        status: null
                    }));
                }}>
                    <Stack spacing={1} direction={'row'} sx={{ alignItems: 'center' }}><Clear
                        color={"inherit"} /><Typography>Clear filter</Typography></Stack>
                </MenuItem></>]
            ) : null}
            {itemProps.colDef.field === 'license' ? (
                [...licenses.map(license => <LicenseMenuComponent key={uuidv4()} {...itemProps} license={license} />),
                <><Divider /><MenuItem onClick={(event) => {
                    props.hideMenu(event);
                    dispatch(setManageUsersFilter({ license: null, status: manageUsersFilter.status ?? null }));
                }}>
                    <Stack spacing={1} direction={'row'} sx={{ alignItems: 'center' }}><Clear
                        color={"inherit"} /><Typography>Clear filter</Typography></Stack>
                </MenuItem></>]
            ) : null}
        </>
    );
}

function StatusMenuComponent({ hideMenu, status }) {
    const dispatch = useDispatch();
    const manageUsersFilter = useSelector((state) => state.data.manageUsersFilter);
    const handleClickStatusFilter = (status) => {
        const filter = status === 'Clear Filter' ? {
            license: null,
            status: null
        } : { license: manageUsersFilter.license ?? null, status: status.status };
        dispatch(setManageUsersFilter(filter));
    };
    return (
        <MenuItem onClick={(event) => {
            hideMenu(event);
            handleClickStatusFilter(status);
        }}>
            <ListItemIcon>
                {getUserStatusIcon(status.status)}
            </ListItemIcon>
            <ListItemText>{status.name}</ListItemText>
        </MenuItem>
    )
}

function LicenseMenuComponent({ hideMenu, license }) {
    const dispatch = useDispatch();
    const manageUsersFilter = useSelector((state) => state.data.manageUsersFilter);
    const handleClickLicenseFilter = (license) => {
        const filter = license === 'Clear Filter' ? { license: null, status: null } : {
            license,
            status: manageUsersFilter.status ?? null
        };
        dispatch(setManageUsersFilter(filter));
    };
    return (
        <MenuItem onClick={(event) => {
            hideMenu(event);
            handleClickLicenseFilter(license);
        }}>
            <ListItemIcon>
                {license === 'Licensed' ? <SvgIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M13 8C13 5.79 11.21 4 9 4C6.79 4 5 5.79 5 8C5 10.21 6.79 12 9 12C11.21 12 13 10.21 13 8ZM1 18V20H17V18C17 15.34 11.67 14 9 14C6.33 14 1 15.34 1 18Z"
                            fill="black" fillOpacity="0.54" />
                        <path d="M23 8L18 13L15 10" stroke="black" strokeOpacity="0.54" strokeWidth="2" />
                    </svg>
                </SvgIcon> : <SvgIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M13 8C13 5.79 11.21 4 9 4C6.79 4 5 5.79 5 8C5 10.21 6.79 12 9 12C11.21 12 13 10.21 13 8ZM1 18V20H17V18C17 15.34 11.67 14 9 14C6.33 14 1 15.34 1 18Z"
                            fill="black" fillOpacity="0.54" />
                        <path d="M22 8L19 11M16 14L19 11M19 11L16 8L22 14" stroke="black" strokeOpacity="0.54"
                            strokeWidth="2" />
                    </svg>
                </SvgIcon>}

            </ListItemIcon>
            <ListItemText><Typography>{license}</Typography></ListItemText>
        </MenuItem>
    )
}

const getUsers = (subscription, contacts, shareableUsers, user, groups) => {
    const licensedUsers = subscription.licensed_users;
    let connectedUsers = licensedUsers.map((connData, index) => {
        const ownerIds = connData.groups.map(group => group.owner_id);
        const foundUsers = licensedUsers.filter(user => ownerIds.includes(user.user_id));
        const groupNames = connData.groups ? connData.groups.map(el => el.name) : [];
        let contact = { ...contacts.find(el => Array.isArray(el.emailAddresses) && el.emailAddresses.some(emailObj => emailObj.value === connData.email)) };
        const isAdmin = connData.is_admin || false;
        if (contact.length) {
            contact.id = connData.email;
            contact.groups = groupNames;
            contact.domainGroups = connData.domains_groups_with_emails;
            contact.status = connData.status || null;
            contact.license = 'Licensed';
            contact.userId = connData.user_id;
            contact.isAdmin = isAdmin;
            contact.name = connData.name || connData.email;
            contact.sharedBy = foundUsers;
        } else {
            const userData = user.user;
            if (connData.email === userData.email) {
                contact = {
                    id: connData.email,
                    email: connData.email,
                    groups: groupNames,
                    domainGroups: connData.domains_groups_with_emails,
                    name: userData.name || userData.email,
                    photo: userData.photo,
                    status: connData.status || null,
                    license: 'Licensed',
                    userId: connData.user_id,
                    isAdmin,
                    sharedBy: foundUsers,
                }
            } else {
                contact = {
                    id: connData.email,
                    email: connData.email,
                    groups: groupNames,
                    domainGroups: connData.domains_groups_with_emails || null,
                    status: connData.status || null,
                    license: 'Licensed',
                    userId: connData.user_id,
                    isAdmin,
                    sharedBy: foundUsers,
                }
                const shareable = shareableUsers.find(el => el.email === connData.email);
                contact.name = shareable ? shareable.name || connData.email : connData.email;
            }
        }
        return contact;
    })
    if (subscription.status !== 2) {
        const restrictedUsers = [];
        connectedUsers = [...connectedUsers, ...restrictedUsers];
        const uniqueDeletedUsers = subscription.deleted_users ? subscription.deleted_users.filter(deletedUser => {
            return !connectedUsers.some(connectedUser => connectedUser.email === deletedUser.email);
        }) : [];
        const deletedUsers = uniqueDeletedUsers.map(item => {
            const contact = contacts.find(el => Array.isArray(el.emailAddresses) && el.emailAddresses.some(emailObj => emailObj.value === item.email));
            const name = contact ? contact.name?.displayName : item.email;
            return {
                ...item,
                status: 'UNLICENSED',
                id: item.user_id,
                license: 'Unlicensed',
                name,
                isAdmin: false,
                sharedBy: [],
            }
        });
        connectedUsers = [...connectedUsers, ...deletedUsers];
    }
    if (user.user.domain_user && user.domainData.users) {
        const uniqueDomainUsers = user.domainData.users.filter(domainUser => {
            return !connectedUsers.some(connectedUser => connectedUser.email === domainUser.email);
        });
        const domainUsers = uniqueDomainUsers.map(domainUser => {
            const licensedUser = subscription.licensed_users.find(licensedUser => licensedUser.email === domainUser.email);
            return {
                id: domainUser.email,
                email: domainUser.email,
                name: domainUser.name?.fullName || domainUser.email,
                groups: null,
                domainGroups: domainUser && domainUser.domains_groups?.length ? domainUser.domains_groups.join(',') : '',
                status: domainUser.status || 'UNLICENSED',
                license: licensedUser ? 'Licensed' : 'Unlicensed',
                isAdmin: domainUser.email === user.user.email && user.user.domain_admin,
                userId: null,
                sharedBy: [],
            }
        });
        connectedUsers = [...connectedUsers, ...domainUsers];
    }

    const waitingLicense = findWaitingLicenseUsers(groups, contacts);
    connectedUsers = mergeUniqueUsers(connectedUsers, waitingLicense);

    connectedUsers = connectedUsers.map((row, index) => ({ ...row, id: index }));
    return connectedUsers
}

function mergeUniqueUsers(initialUsers, newUsers) {
    const existingEmails = new Set(
        initialUsers.map(user => user.email)
    );
    const filteredNewUsers = newUsers.filter(
        user => !existingEmails.has(user.email)
    );
    return [...initialUsers, ...filteredNewUsers];
}

const findWaitingLicenseUsers = (groups, contacts) => {
    return groups
        .filter(group => group.share?.length)
        .reduce((acc, group) => {
            const waitingUsers = group.share
                .filter(user => user.sync_status === 'WAITING_LICENSE')
                .map((user) => {
                    const contact = contacts.find(el => Array.isArray(el.emailAddresses) && el.emailAddresses.some(emailObj => emailObj.value === user.email));
                    return { ...user, status: user.sync_status, license: 'Unlicensed', name: contact ? contact.name?.displayName : user.email }
                });
            acc.push(...waitingUsers);
            return acc;
        }, []);
};

const getUserStatus = (subscription, connData) => {
    const isDeleted = subscription.deleted_users ? subscription.deleted_users.find(item => item.email === connData.email) : false;
    if (isDeleted) return 'Deleted';
    const isBanned = subscription.restricted_users ? subscription.restricted_users.find(item => item.email === connData.email) : false;
    if (isBanned) return 'Banned';
    return 'Normal'
}

const AvatarSharedBy = ({ sharedUsers }) => {
    const extraUsers = sharedUsers.slice(4);
    const maxUsersInTooltip = 20;
    const tooltipText = extraUsers.length > 0
        ? extraUsers.slice(0, maxUsersInTooltip).map(user => user.email).join('\n') + (extraUsers.length > maxUsersInTooltip ? '\n...' : '')
        : '';
    return (
        <AvatarGroup max={5}
            key={'SharedUsersAvatar'}
            spacing={'medium'}
            renderSurplus={(surplus) => <Tooltip
                placement="top"
                title={<span style={{ whiteSpace: 'pre-line' }}>{tooltipText}</span>}>
                <span>+{surplus}</span>
            </Tooltip>}
            total={sharedUsers.length < 103 ? sharedUsers.length : 99 + 4}
            sx={{
                '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontSize: 13,
                    fontFamily: "Open Sans",
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '16px',
                },
            }}
        >
            {
                sharedUsers.map((sharedUser, index) => {
                    return (
                        <Tooltip
                            placement="top"
                            title={sharedUser.email}
                            key={index}
                        >
                            <Badge
                                key={index}
                                variant={'dot'}
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                            >
                                <UserAvatar alt={sharedUser.name}
                                    sx={{ width: 32, height: 32 }}
                                    src={sharedUser.photo} />
                            </Badge>
                        </Tooltip>
                    )
                })
            }
        </AvatarGroup>
    )
}

const menuItemStyles = {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: '#212121',
};

function ActionMenu({ params, handleRemoveLicenseClick, handleAddUserToSubscriptionClick, handleExterminateUserClick, handleRemoveAdminPermissionClick, handleAddAdminPermissionClick }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertOutlined
                    sx={{
                        width: 24,
                        height: 24,
                        flexShrink: 0,
                        color: 'rgba(0, 0, 0, 0.54)',
                    }}
                />
            </IconButton>

            <Paper sx={{ width: 320, maxWidth: '100%' }}>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuList>
                        {params.row.isAdmin ? <MenuItem
                            onClick={(event) => {
                                handleRemoveAdminPermissionClick(event, params.row);
                                handleClose();
                            }}
                        >
                            <ListItemIcon>
                                <SvgIcon>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.28961 7.69961L2.09961 3.50961L3.51961 2.09961L21.8996 20.4896L20.4896 21.8996L6.28961 7.69961Z" fill="#757575" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.43439 8.05338L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27L18.18 21L16.9119 15.5309L14.7759 13.3949L14.77 13.4L15.77 17.68L12 15.4L8.24 17.67L9.24 13.39L5.92 10.51L10.3 10.13L10.6593 9.27827L9.43439 8.05338ZM13.2437 9.03838L12 6.1L11.4975 7.29121L10.2756 6.06863L12 2L14.81 8.62L22 9.24L17.4189 13.2159L16.2874 12.0837L18.09 10.52L14.405 10.2003L13.2437 9.03838Z" fill="#757575" />
                                    </svg>
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText><Typography variant={'settingsMenu'} sx={{ color: '#212121' }}>Remove admin</Typography></ListItemText>
                        </MenuItem> :
                            <MenuItem
                                onClick={(event) => {
                                    handleAddAdminPermissionClick(event, params.row);
                                    handleClose();
                                }}
                                sx={menuItemStyles}
                            >
                                <ListItemIcon>
                                    <SvgIcon>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.81 8.62L22 9.24L19.9721 11H17.5367L18.09 10.52L13.71 10.14L12 6.1L10.3 10.13L5.92 10.51L9.24 13.39L8.24 17.67L11 16.0037V17.8736L5.82 21L7.46 13.97L2 9.24L9.19 8.63L12 2L14.81 8.62Z" fill="#757575" />
                                            <path d="M21 18H18V21H16V18H13V16H16V13H18V16H21V18Z" fill="#757575" />
                                        </svg>
                                    </SvgIcon>
                                </ListItemIcon>
                                <ListItemText><Typography variant={'settingsMenu'} sx={{ color: '#212121' }}>Appoint admin</Typography></ListItemText>
                            </MenuItem>}
                        {params.row.license === 'Licensed' ? !params.row.isAdmin ? <MenuItem onClick={(event) => {
                            handleRemoveLicenseClick(event, params.row);
                            handleClose();
                        }}>
                            <ListItemIcon>
                                <SvgIcon>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.58063 8.41055L1.39062 4.22055L2.81062 2.81055L21.1906 21.2005L19.7806 22.6105L5.58063 8.41055Z" fill="#757575" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5 5.01937L5.58063 5.6L7 7.01937V18L12 15.82L17 18V17.0194L19 19.0194V21L12 18L5 21V5.01937ZM19 16.1982V5C19 3.9 18.1 3 17 3H7C6.6569 3 6.33325 3.08756 6.0503 3.24144L7.80791 5H17V14.1971L19 16.1982Z" fill="#757575" />
                                    </svg>
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText><Typography variant={'settingsMenu'} sx={{ color: '#212121' }}>Remove license</Typography></ListItemText>
                        </MenuItem> : null :
                            <MenuItem onClick={(event) => {
                                handleAddUserToSubscriptionClick(event, params.row);
                                handleClose();
                            }}>
                                <ListItemIcon>
                                    <BookmarkAddOutlined sx={{ color: '#757575' }} />
                                </ListItemIcon>
                                <ListItemText><Typography variant={'settingsMenu'} sx={{ color: '#212121' }}>Add license</Typography></ListItemText>
                            </MenuItem>
                        }
                        {!params.row.isAdmin ? <MenuItem onClick={(event) => {
                            handleExterminateUserClick(event, params.row);
                            handleClose();
                        }}
                        >
                            <ListItemIcon>
                                <DeleteOutlineOutlined sx={{ color: '#757575' }} />
                            </ListItemIcon>
                            <ListItemText><Typography variant={'settingsMenu'} sx={{ color: '#212121' }}>Delete user</Typography></ListItemText>
                        </MenuItem> : null}
                    </MenuList>
                </Menu>
            </Paper>
        </div>
    );
}

// Create a component to display an avatar or checkbox
const AvatarWithCheckbox = ({ params, selected, onCheckboxChange, getPhotoFormContacts }) => {
    // Обработчик наведения на чекбокс
    const handleMouseEnter = (e) => {
        e.stopPropagation();
        // Находим ближайшую строку и добавляем класс
        const row = e.currentTarget.closest('.MuiDataGrid-row');
        if (row) {
            row.classList.add('checkbox-hovered');
        }
    };

    // Обработчик ухода мыши с чекбокса
    const handleMouseLeave = (e) => {
        e.stopPropagation();
        // Находим ближайшую строку и удаляем класс
        const row = e.currentTarget.closest('.MuiDataGrid-row');
        if (row) {
            row.classList.remove('checkbox-hovered');
        }
    };

    // Обработчик для клавиатуры
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.stopPropagation();
            onCheckboxChange(e, params.row.id);
        }
    };

    return (
        <div
            className="avatar-checkbox-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={(e) => {
                e.stopPropagation();
                onCheckboxChange(e, params.row.id);
            }}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
            aria-label={selected ? "Снять выделение" : "Выделить строку"}
        >
            <div className="avatar-container">
                <UserAvatar
                    alt={params.row.name}
                    src={getPhotoFormContacts(params.row.email)}
                    sx={{ width: 40, height: 40 }}
                />
            </div>
            <div
                className="checkbox-container"
            >
                <Checkbox
                    checked={selected}
                    onChange={(e) => onCheckboxChange(e, params.row.id)}
                    onClick={(e) => e.stopPropagation()}
                    sx={{
                        padding: 0,
                        width: 40,
                        height: 40,
                        '& .MuiSvgIcon-root': {
                            width: 24,
                            height: 24
                        }
                    }}
                />
            </div>
        </div>
    );
};
