import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from "react-redux";
import { Grid, IconButton, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

// project import
import { openDeleteUserFromManageUsersDialog } from "../../store/reducers/dialogSlice";
import { exterminateDeletedSubscriptionUsers, setUsersToDelete, setSelectedUsers, getSubscriptionData, removeUsersFromSubscription } from "../../store/reducers/userSlice";
import { removeSharedUsersFromGroups, changeContacts } from "../../store/reducers/groupsSlice";

export default function AlertDeleteUserDialog() {
    const dispatch = useDispatch();
    const open = useSelector((state) => state.dialogsOpen.deleteUserFromManageUsers.value);
    const usersToDelete = useSelector((state) => state.user.usersToDelete);
    const exterminateDeletedSubscriptionUsersStatus = useSelector((state) => state.user.exterminateDeletedSubscriptionUsersStatus);
    const removeUsersFromSubscriptionStatus = useSelector((state) => state.user.removeUsersFromSubscriptionStatus);
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const loading = exterminateDeletedSubscriptionUsersStatus === 'loading' || removeUsersFromSubscriptionStatus === 'loading';
    const handleClose = () => {
        dispatch(openDeleteUserFromManageUsersDialog(false));
    };
    const handleDeleteUsers = async () => {
        const emails = usersToDelete.map(item => item.email);
        const usersIds = usersToDelete.map(item => item.userId || item.user_id);
        await dispatch(removeSharedUsersFromGroups({ usersIds }));
        await dispatch(exterminateDeletedSubscriptionUsers({ emails }));
        dispatch(changeContacts({ resource: currentGroup.resource }));
        handleClose();
        await dispatch(getSubscriptionData());
        dispatch(setUsersToDelete([]));
        dispatch(setSelectedUsers([]));
    };

    const hasAdmin = usersToDelete.some(item => item.isAdmin);
    const plural = usersToDelete.length > 1 || usersToDelete.length === 0;
    const userText = plural ? 'users' : 'user';
    const message = hasAdmin
        ? 'You cannot delete admin users. Please remove their admin rights first.'
        : `You're about to delete the selected ${userText}. This action will remove them from Shared Contacts Manager and release their licenses.`;

    const CancelButton = () => {
        if (hasAdmin) {
            return null;
        }
        return (
            <Button onClick={handleClose}>
                <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
            </Button>
        );
    };
    const CloseButton = () => {
        if (!hasAdmin) {
            return null;
        }
        return (
            <Button onClick={handleClose}>
                <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Close</Typography>
            </Button>
        );
    };
    const ConfirmButton = () => {
        if (hasAdmin) {
            return null;
        }
        return (
            <LoadingButton
                loading={loading}
                disabled={loading}
                onClick={handleDeleteUsers}
            >
                <Typography variant={'dialogButton'} sx={{
                    color: loading ? 'rgba(0, 0, 0, 0.32)' : 'rgba(59, 125, 237, 1)'
                }}>Confirm</Typography>
            </LoadingButton>
        );
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    minWidth: 444,
                    backgroundColor: 'rgba(59, 125, 237, 1)'
                }
            }}
        >
            <DialogTitle sx={{ backgroundColor: 'rgba(59, 125, 237, 1)' }}>
                <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Warning</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined />
            </IconButton>
            <DialogContent dividers sx={{ border: 0, backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                <Typography
                    sx={{
                        color: 'rgba(0, 0, 0, 0.87)',
                        fontSize: 16,
                        fontFamily: `"Roboto"`,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.2px',
                    }}
                >
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                <CancelButton />
                <CloseButton />
                <ConfirmButton />
            </DialogActions>
        </Dialog>
    );
}

