import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from "react-redux";
import { Grid, IconButton, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

// project import
import store from "../../store/store";
import { openAlertRemoveContactFromLabelDialog, openAlertCantDeleteContactFromLabel } from "../../store/reducers/dialogSlice";
import { removeContactsFromGroup, setDeleteCurrentGroup } from "../../store/reducers/groupsSlice";
import { deleteContactsFromGroup } from "../../store/reducers/contactsSlice";

export default function AlertRemoveContactFromLabelDialog() {
    const open = useSelector((state) => state.dialogsOpen.alertRemoveContactFromLabel.value);
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const removeContactsFromGroupStatus = useSelector((state) => state.groups.removeContactsFromGroupStatus);
    const loading = removeContactsFromGroupStatus === 'loading';
    const groups = useSelector((state) => state.groups);
    const rowSelectionModel = useSelector((state) => state.contacts.rowSelectionModel);
    const handleClose = () => {
        store.dispatch(setDeleteCurrentGroup(false));
        store.dispatch(openAlertRemoveContactFromLabelDialog(false));
    };
    const handleRemove = async () => {
        handleClose();
        const hasGroups = findGroupsWithContacts(rowSelectionModel, currentGroup.resource);
        if (!hasGroups) {
            store.dispatch(openAlertCantDeleteContactFromLabel(true));
            return;
        }
        const result = await store.dispatch(removeContactsFromGroup({
            groupResource: currentGroup.resource,
            contactsResources: rowSelectionModel
        }));
        store.dispatch(deleteContactsFromGroup(result));
    };
    const findGroupsWithContacts = (searchContacts, excludeGroupId) => {
        // Convert search contacts to Set for O(1) lookup
        const searchSet = new Set(searchContacts);
        const searchSize = searchSet.size;
        // Return early if no contacts to search
        if (searchSize === 0) return false;
        return groups.groups.some(group => {
            // Skip excluded group and empty groups
            if (
                group.resource === excludeGroupId ||
                !group.contacts_resources.length ||
                group.contacts_resources.length < searchSize
            ) {
                return false;
            }
            // Convert group contacts to Set only if group passes initial check
            const groupSet = new Set(group.contacts_resources);
            return searchContacts.every(contact => groupSet.has(contact));
        });
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-delete-label-dialog-title"
            aria-describedby="alert-delete-label-dialog-description"
            PaperProps={{
                sx: {
                    minWidth: 444,
                    backgroundColor: 'rgba(59, 125, 237, 1)'
                }
            }}
        >
            <DialogTitle sx={{ backgroundColor: 'rgba(59, 125, 237, 1)' }}>
                <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Remove contacts from label</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined />
            </IconButton>
            <DialogContent dividers sx={{ border: 0, backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                <Typography
                    noWrap
                    sx={{
                        color: 'rgba(0, 0, 0, 0.54)',
                        fontSize: 16,
                        fontFamily: `"Roboto"`,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.2px',
                        marginBottom: 1,
                    }}
                >
                    Are you sure you want to remove {rowSelectionModel.length} contacts from the
                    "{currentGroup?.name}" label?
                </Typography>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                <Button onClick={handleClose}>
                    <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
                </Button>
                <LoadingButton loading={loading} onClick={handleRemove}>
                    <Typography variant={'dialogButton'}
                        sx={{
                            color: loading ? 'rgba(0, 0, 0, 0.32)' : 'rgba(59, 125, 237, 1)'
                        }}
                    >Delete</Typography>
                </LoadingButton>
                {/* <Button onClick={handleRemove}>
                    <Typography variant={'dialogButton'} color={'rgba(59, 125, 237, 1)'}>Delete</Typography>
                </Button> */}
            </DialogActions>
        </Dialog>
    );
}
