import { Box, SvgIcon } from "@mui/material";
import { CheckCircle, ErrorOutlined, RemoveCircleOutlined } from "@mui/icons-material";
import Lottie from "lottie-react";

import animation from "../../loading.json";

const getSyncStatusText = (status) => {
    let result = '';
    switch (status) {
        case 'ON_QUEUE':
            result = 'Sharing...';
            break;
        case 'OK':
            result = 'Shared';
            break;
        case 'WAITING_CONTACTS':
            result = 'Sharing...';
            break;
        case 'WAITING_LICENSE':
            result = 'Unlicensed';
            break;
        case 'REVOKE':
            result = 'Revoked';
            break;
        case 'PENDING':
            result = 'Pending';
            break;
        case 'BANNED':
            result = 'Banned';
            break;
        default:
            result = 'Waiting for access';
    }
    return result;
}

const getUserStatusIconShareLabelDialog = (status) => {
    const size = 24;
    let result = '';
    status = status?.toUpperCase();
    // status = 'ON_QUEUE';
    switch (status) {
        case 'OK':
            result = <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.66602 10.0013C1.66602 5.4013 5.39935 1.66797 9.99935 1.66797C14.5993 1.66797 18.3327 5.4013 18.3327 10.0013C18.3327 14.6013 14.5993 18.3346 9.99935 18.3346C5.39935 18.3346 1.66602 14.6013 1.66602 10.0013ZM8.33333 11.81L13.825 6.31836L15 7.50169L8.33333 14.1684L5 10.835L6.175 9.66003L8.33333 11.81Z" fill="#2E7D32" />
                </svg>
            </SvgIcon>;
            break;
        case 'SHARED': // TODO: check if this is correct 24.03.2025
            result = <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.99935 1.66797C5.39935 1.66797 1.66602 5.4013 1.66602 10.0013C1.66602 14.6013 5.39935 18.3346 9.99935 18.3346C11.8751 18.3346 13.6068 17.7138 15 16.6666V7.68945H18.0074C17.0045 4.21295 13.7973 1.66797 9.99935 1.66797ZM13.825 6.31836L8.33333 11.81L6.175 9.66003L5 10.835L8.33333 14.1684L15 7.50169L13.825 6.31836Z" fill="#ED9F02" />
                    <path d="M16.666 16.6654H18.3327V18.332H16.666V16.6654ZM18.3327 9.33203H16.666V14.9987H18.3327V9.33203Z" fill="#ED9F02" />
                </svg>
            </SvgIcon>;
            break;
        case 'ON_QUEUE':
        case 'WAITING_CONTACTS':
            result = <Lottie style={{ width: size, height: size }} animationData={animation} loop />;
            break;
        case 'WAITING_LICENSE':
            result = <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.99935 1.66797C5.39935 1.66797 1.66602 5.4013 1.66602 10.0013C1.66602 14.6013 5.39935 18.3346 9.99935 18.3346C14.5993 18.3346 18.3327 14.6013 18.3327 10.0013C18.3327 5.4013 14.5993 1.66797 9.99935 1.66797Z" fill="#D32F2F" />
                    <path d="M12.9165 4.75H7.08317C6.4415 4.75 5.9165 5.275 5.9165 5.91667V15.25L9.99984 13.5L14.0832 15.25V5.91667C14.0832 5.275 13.5582 4.75 12.9165 4.75ZM12.9165 13.5L9.99984 12.2283L7.08317 13.5V5.91667H12.9165V13.5Z" fill="white" />
                </svg>
            </SvgIcon>;
            break;
        case 'PENDING':
            result = <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.9915 1.66675C5.3915 1.66675 1.6665 5.40008 1.6665 10.0001C1.6665 14.6001 5.3915 18.3334 9.9915 18.3334C14.5998 18.3334 18.3332 14.6001 18.3332 10.0001C18.3332 5.40008 14.5998 1.66675 9.9915 1.66675ZM12.7415 13.9251L9.1665 10.3417V5.83341H10.8332V9.65841L13.9248 12.7501L12.7415 13.9251Z" fill="#ED9F02" />
                </svg>
            </SvgIcon>;
            break;
        case 'REVOKE':
            result = <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.66602 10.0013C1.66602 5.4013 5.39935 1.66797 9.99935 1.66797C14.5993 1.66797 18.3327 5.4013 18.3327 10.0013C18.3327 14.6013 14.5993 18.3346 9.99935 18.3346C5.39935 18.3346 1.66602 14.6013 1.66602 10.0013ZM9.9987 8.8276L12.9904 5.83594L14.1654 7.01094L11.1737 10.0026L14.1654 12.9943L12.9904 14.1693L9.9987 11.1776L7.00703 14.1693L5.83203 12.9943L8.8237 10.0026L5.83203 7.01094L7.00703 5.83594L9.9987 8.8276Z" fill="#D32F2F" />
                </svg>
            </SvgIcon>;
            break;
        case 'BANNED':
            result = <RemoveCircleOutlined color={"error"} />;
            break;
        default:
            result = <ErrorOutlined color={'error'} />;
    }
    return result;
}

const getSyncStatusIcon = (status, size = 24) => {
    let result = '';
    switch (status) {
        case 'ON_QUEUE':
            result = <Lottie style={{ width: size, height: size }} animationData={animation} loop />;
            break;
        case 'OK':
            result = <CheckCircle sx={{ width: size, height: size }} color={'success'} />;
            break;
        case 'WAITING_LABEL':
            result = <ErrorOutlined sx={{ width: size, height: size }} color={'error'} />;
            break;
        case 'WAITING_CONTACTS':
            result = <Lottie style={{ width: size, height: size }} animationData={animation} loop />;
            break;
        case 'WAITING_LICENSE':
            result = <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.99935 1.66797C5.39935 1.66797 1.66602 5.4013 1.66602 10.0013C1.66602 14.6013 5.39935 18.3346 9.99935 18.3346C14.5993 18.3346 18.3327 14.6013 18.3327 10.0013C18.3327 5.4013 14.5993 1.66797 9.99935 1.66797Z" fill="#D32F2F" />
                    <path d="M12.9165 4.75H7.08317C6.4415 4.75 5.9165 5.275 5.9165 5.91667V15.25L9.99984 13.5L14.0832 15.25V5.91667C14.0832 5.275 13.5582 4.75 12.9165 4.75ZM12.9165 13.5L9.99984 12.2283L7.08317 13.5V5.91667H12.9165V13.5Z" fill="white" />
                </svg>
            </SvgIcon>;
            break;
        default:
            result = <ErrorOutlined sx={{ width: size, height: size }} color={'error'} />;
    }
    return result;
}

const getUserStatusIconContactsAppBar = (status) => {
    let result = '';
    status = status?.toUpperCase();
    // status = 'ON_QUEUE';
    switch (status) {
        case 'OK':
            result = <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="18" height="18" rx="9" fill="#2E7D32" />
                    <rect x="1" y="1" width="18" height="18" rx="9" stroke="white" strokeWidth="2" />
                    <path d="M8.25009 12.4324L5.81759 9.99991L4.98926 10.8224L8.25009 14.0832L15.2501 7.08324L14.4276 6.26074L8.25009 12.4324Z" fill="white" />
                </svg>
            </SvgIcon>;
            break;
        case 'SHARED': // TODO: check if this is correct 24.03.2025
            result = <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="18" height="18" rx="9" stroke="white" strokeWidth="2" />
                    <path d="M10 2C5.584 2 2 5.584 2 10C2 14.416 5.584 18 10 18C14.416 18 18 14.416 18 10C18 5.584 14.416 2 10 2Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M10 2C5.584 2 2 5.584 2 10C2 14.416 5.584 18 10 18C11.8914 18 13.6301 17.3426 15 16.2439V7.68945H17.6608C16.6687 4.39878 13.6128 2 10 2ZM13.825 6.31836L8.33333 11.81L6.175 9.66003L5 10.835L8.33333 14.1684L15 7.50169L13.825 6.31836Z" fill="#ED9F02" />
                    <path d="M16.666 16.6654H18.3327V18.332H16.666V16.6654ZM18.3327 9.33203H16.666V14.9987H18.3327V9.33203Z" fill="#ED9F02" />
                </svg>
            </SvgIcon>;
            break;
        case 'ON_QUEUE':
        case 'WAITING_CONTACTS':
            result = <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="18" height="18" rx="9" fill="#ED9F02" />
                    <rect x="1" y="1" width="18" height="18" rx="9" stroke="white" strokeWidth="2" />
                    <path d="M10.0002 5.33325V3.58325L7.66683 5.91659L10.0002 8.24992V6.49992C11.931 6.49992 13.5002 8.06909 13.5002 9.99992C13.5002 10.5891 13.3543 11.1491 13.0918 11.6333L13.9435 12.4849C14.3985 11.7674 14.6668 10.9158 14.6668 9.99992C14.6668 7.42159 12.5785 5.33325 10.0002 5.33325ZM10.0002 13.4999C8.06933 13.4999 6.50016 11.9308 6.50016 9.99992C6.50016 9.41075 6.646 8.85075 6.9085 8.36659L6.05683 7.51492C5.60183 8.23242 5.3335 9.08409 5.3335 9.99992C5.3335 12.5783 7.42183 14.6666 10.0002 14.6666V16.4166L12.3335 14.0833L10.0002 11.7499V13.4999Z" fill="white" />
                </svg>
            </SvgIcon>;
            break;
        case 'WAITING_LICENSE':
            result = <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="18" height="18" rx="9" fill="#D32F2F" />
                    <rect x="1" y="1" width="18" height="18" rx="9" stroke="white" strokeWidth="2" />
                    <path d="M12.9165 4.75H7.08317C6.4415 4.75 5.9165 5.275 5.9165 5.91667V15.25L9.99984 13.5L14.0832 15.25V5.91667C14.0832 5.275 13.5582 4.75 12.9165 4.75ZM12.9165 13.5L9.99984 12.2283L7.08317 13.5V5.91667H12.9165V13.5Z" fill="white" />
                </svg>
            </SvgIcon>;
            break;
        case 'PENDING':
            result = <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="18" height="18" rx="9" fill="#ED9F02" />
                    <rect x="1" y="1" width="18" height="18" rx="9" stroke="white" strokeWidth="2" />
                    <path d="M11.575 14.0917L8 10.5083V6H9.66667V9.825L12.7583 12.9167L11.575 14.0917Z" fill="white" />
                </svg>
            </SvgIcon>;
            break;
        case 'REVOKE':
            result = <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="18" height="18" rx="9" fill="#D32F2F" />
                    <rect x="1" y="1" width="18" height="18" rx="9" stroke="white" strokeWidth="2" />
                    <path d="M12.872 6L10 8.872L7.128 6L6 7.128L8.872 10L6 12.872L7.128 14L10 11.128L12.872 14L14 12.872L11.128 10L14 7.128L12.872 6Z" fill="white" />
                </svg>
            </SvgIcon>;
            break;
        case 'BANNED':
            result = <RemoveCircleOutlined color={"error"} />;
            break;
        default:
            result = <ErrorOutlined color={'error'} />;
    }
    return result;
}

export {
    getSyncStatusText,
    getSyncStatusIcon,
    getUserStatusIconShareLabelDialog,
    getUserStatusIconContactsAppBar,
}
